import { Component, OnInit, inject, ViewChild, TemplateRef, ViewEncapsulation } from '@angular/core';
import { RouterLink, Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../../../../services/auth.service';
import { CommonModule } from '@angular/common';
import { ApiService } from '../../../../services/api.service';
import { NamingConventionService } from '../../../../services/naming-convention.service';
import { NamingConvention } from '../../../../interfaces/naming-convention';
import { NamingConventionField } from '../../../../interfaces/naming-convention';
import { ToastrService } from 'ngx-toastr';
import * as _ from 'lodash';
import { FormsModule, NgForm } from '@angular/forms'
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { Image } from '../../../../interfaces/image';
import { Creative } from '../../../../interfaces/creative';
import { Page } from '../../../../interfaces/page';
import { regexPatterns } from '../../regex-patterns';
import { Ad, AdAccount } from '../../../../interfaces/ad-account';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-waterdrop-anz',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    FormsModule,
    NgbTooltipModule
  ],
  templateUrl: './waterdrop-anz.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrl: './waterdrop-anz.component.css'
})
export class NcGeneratorWaterdropAnzComponent implements OnInit  {
  router: Router = inject(Router);
  authService: AuthService = inject(AuthService);
  apiService: ApiService = inject(ApiService);
  namingConventionService: NamingConventionService = inject(NamingConventionService);
  toastrService: ToastrService = inject(ToastrService);
  activatedRoute: ActivatedRoute = inject(ActivatedRoute);
  modalService = inject(NgbModal);

  // User Authentication
  authenticatedUser = this.authService.authenticatedUser;

  // Component-specific variables
  accountId = 'waterdrop-anz'; // Waterdrop ANZ Hubble account ID
  copyMatrixCols = {
    'HDL': 1,
    'PRT': 5,
    'DES': 3,
    'URL': 17
  }; // Waterdrop ANZ column order in the Google Sheets matrix
  // End of Component-specific variables. Other variables values will be retrieved from the server.

  namingConvention: NamingConvention = {
    id: this.accountId
  };
  
  adAccount: AdAccount = {
    id: this.accountId,
  };
  adAccountLoading = true;
  adAccountLoaded = false;
  
  account_id: string = '';
  copyMatrixUrl: string = '';
  creativeMatrixUrl: string = '';

  accountAdsets: {
    id: string,
    name: string,
    created_time: string,
    campaign_name: string
  }[] = [];
  accountAdsetsLoading = false;  

  @ViewChild('namingForm') namingForm!: NgForm;

  namingConventionLoading = true;
  namingConventionLoaded = false;

  adsetId: string = '';
  adsetName: string = '';
  adId: string = '';
  adImageId: string | null = null;
  images: Image[] = [];
  imagesloading: boolean = false;
  selectedImageHash: string | null = null;
  adsetCreatives: Creative[] = [];
  adsetCreativesLoading: boolean = false;
  selectedCreativeId: string | null = null;
  showRenameAd: boolean = false;
  showCreateAd: boolean = false;
  urlCreatedAd: string | null = null;
  pageIds: Page[] = [];
  pageIdsLoading: boolean = false;
  selectedPageId: string | null = null;

  todayDate: string = new Date().toISOString().slice(0, 10).replace(/-/g, '');
  // Campaign level
  campaign_launch_date: string = this.todayDate;
  client: string = this.namingConvention.id;
  campaign_unique_id: string = '';
  phase_number: string = '1';
  funnel_stage: string = '';
  campaign_type: string = '';
  budget_type: string = '';
  conversion_event: string = 'PURCHASE';
  country_code: string = 'AUS';
  // Adset level
  adset_launch_date: string = this.todayDate;
  bid_type: string = 'MAXCONV';
  audience_type: string = 'BROAD';
  exclusion_type: string = 'EXLXXX';
  adset_test_id: string = 'XXXTSTXXX';
  adset_unique_id: string = '';
  // Ad level
  creative: string = 'IMGXXX';
  headline: string = 'HDLXXX';
  primary_text: string = 'PRTXXX';
  description: string = 'DESXXX';
  creative_url: string = 'URLXXX';
  click_destination: string = '';
  creative_source: string = 'WEBPROFITS';
  product_category: string = '';
  product_code: string = '';
  post_id: string = 'POSTIDXXX';

  queryParamName: string | null = null;

  creativeMetaThumbnailUrl: string | null = null;
  creativeMetaThumbnailLoading: boolean = false;
  creativeCheckedInMeta: boolean = false;
  creativeCheckedInMatrix: boolean = false;
  headlineMatrixValue: string | null = null;
  headlineMatrixLoading: boolean = false;
  primary_textMatrixValue: string | null = null;
  primary_textMatrixLoading: boolean = false;
  descriptionMatrixValue: string | null = null;
  descriptionMatrixLoading: boolean = false;
  creative_urlMatrixValue: string | null = null;
  creative_urlMatrixLoading: boolean = false;
  creativeMatrixValue: string | null = null;
  creativeMatrixLoading: boolean = false;
  creativeUrlTags: string = 'utm_source=facebook&utm_medium=cpc&utm_campaign={{campaign.name}}&utm_content={{adset.name}}&utm_term={{ad.name}}&tw_source={{site_source_name}}&tw_adid={{ad.id}}';

  generatedName: string = '';
  generatedNameClass: string = 'alert-warning';

  generatedNameValid: boolean = false;

  loadingAdRename = false;

  loadingAdCreate = false;

  showImportName = false;
  existingName: string = '';
  placeholderImportName: string = this.todayDate + '_' + this.client + '_...';

  regexPatterns: { [key: string]: RegExp } = regexPatterns;

  fields: { [key: string]: any } = {};

    
  ngOnInit(): void {
    // Check that user is authenticated (should be the case if this path is protected with AuthGuardService in app.routes.ts)
    this.authService.checkUserSignedIn().then(checkSign => {
      if (!checkSign) {
        // User is NOT signed in => Call AuthService.signOut() to redirect to Sign in page
        this.authService.signOut();
      } else {
        // User is signed in
        // Check if the user has access to the Ad Accounts section
        this.authService.checkUserHasAccessToAdAccounts().then(adAccounts => {
          if(adAccounts === null || !adAccounts.some(adAccount => adAccount.id === this.namingConvention.id)) {
            // User does not have access to the account (or the user has no ad accounts at all)
            console.log("User does not have access to this ad account");
            this.toastrService.error("You do not have access to this Ad Account", "Access Denied");
            this.router.navigate(['/']);
          } else {
            // User has access to the account
            console.log("User has access to this ad account");

            // Get ad account details from the server
            this.adAccountLoading = true;
            let requestPath = `/ad-accounts?get=${this.accountId}`;
            this.apiService.get(requestPath)
            .then(response => {
              console.log("[Ad account details] Get response", response);  
              this.adAccountLoading = false; 
              this.adAccountLoaded = true;
              this.adAccount = {
                id: this.accountId, 
                name: response.data.name,
                meta_id: response.data.meta_id,
                gs_ads_library_id: response.data.gs_ads_library_id,
                gs_ads_library_copy_url: response.data.gs_ads_library_copy_url,
                gs_ads_library_creatives_url: response.data.gs_ads_library_creatives_url,
                gd_creatives_folder_id: response.data.gd_creatives_folder_id,
              };
              if(!response.data.meta_id) this.toastrService.error('No Meta ID found for this Ad Account', 'Error');
              if(!response.data.gs_ads_library_id) this.toastrService.error('No Google Sheet Ads Library ID found for this Ad Account', 'Error');
              if(!response.data.gs_ads_library_copy_url) this.toastrService.error('No Google Sheet Ads Library Copy URL found for this Ad Account', 'Error');
              if(!response.data.gs_ads_library_creatives_url) this.toastrService.error('No Google Sheet Ads Library Creatives URL found for this Ad Account', 'Error');
              if(!response.data.gd_creatives_folder_id) this.toastrService.error('No Google Drive Creatives Folder ID found for this Ad Account', 'Error');
              this.account_id = response.data.meta_id;
              this.copyMatrixUrl = response.data.gs_ads_library_copy_url;
              this.creativeMatrixUrl = response.data.gs_ads_library_creatives_url;

              // Load the adsets for the account
              this.getAccountAdsets();

                // Get the query parameters from the URL
                this.activatedRoute.queryParamMap.subscribe(params => {
                  if(params.get('ad')) {
                    this.adId = params.get('ad')!;
                  }

                  if(params.get('adset')) {
                    this.adsetId = params.get('adset')!;
                  }
                  if(params.get('adset_name')) {
                    this.adsetName = params.get('adset_name')!;
                  }
  
                  if(params.get('name') || params.get('name') != '') {
                    this.queryParamName = params.get('name') || '';                    
                  }

                  // Load the Naming Convention for the Ad Account
                  this.namingConventionService.setId(this.accountId);
                  this.loadNamingConvention()
                  .then(() => {
                    // Naming convention loaded
                    console.log("Naming Convention loaded");
                    if(this.queryParamName && this.queryParamName != '') {
                      this.importName(this.queryParamName);
                    }

                    this.generateName();

                });


              });           
            }).catch(error => {
              console.log("[Ad account details] Get error", error);
              this.adAccountLoading = false;
              this.toastrService.error(error.message, 'Failed to load Ad Account');
            });            
          }        
        });
      }  
    });
  }

  ngAfterViewChecked() {
    // Validate the form after generating the name to show any errors in the UI
    // In ngOnInit(), the form is not yet available. It is only available in ngAfterViewChecked() because the form is only visible after the naming convention is loaded.
    if(this.queryParamName && this.queryParamName != '') {
      // Only do it on page load if a query parameter name has been provided (i.e editing an existing ad)
      this.validateAllFields(this.namingForm);
    }

  }


  loadNamingConvention(): Promise<NamingConvention> {
    // Load the Naming Convention for the Ad Account
    return new Promise<NamingConvention>((resolve, reject) => {
      console.log("["+this.constructor.name+"] Loading Naming Convention for Ad Account", this.namingConvention.id);
      this.namingConventionLoading = true;
      this.namingConventionLoaded = false;
      this.namingConventionService.get()
      .then(response => {
        // console.log("["+this.constructor.name+"] Naming Convention retrieved", response);
        this.namingConvention = response;
        this.namingConvention.id = this.accountId; // Re-inject the account ID
        _.forEach(this.namingConvention.fields, (field: NamingConventionField) => {
          // console.log("["+this.constructor.name+"] Field", field.id, field.name, field.validation);
          this.fields[field.id] = {
            name: field.name,
            error: field.error,
            placeholder: field.placeholder,
            description: field.description
          };
          if(field.validation.regex) {
            // If field.validation.regex) starts with "regexPatterns.", replace it with the actual regex pattern from regexPatterns.ts
            if(field.validation.regex.startsWith('regexPatterns.')) {
              // Standard regex pattern from regexPatterns.ts 
              _.forEach(this.regexPatterns, (value, key) => {
                if(field.validation.regex === 'regexPatterns.' + key) {                        
                  this.fields[field.id].regex = new RegExp(value);
                }
              });
            } else {
              // Custom regex pattern
              this.fields[field.id].regex = new RegExp(field.validation.regex);
            }
          } 
          if(field.allowed_values) {
            this.fields[field.id].allowed_values = field.allowed_values;
            // Order by key
            this.fields[field.id].allowed_values = _.sortBy(this.fields[field.id].allowed_values, 'key');
            let regexAllowedValues: string[] = [];
            _.forEach(field.allowed_values, (val) => {
              regexAllowedValues.push(val.key);
            });
            this.fields[field.id].regex = new RegExp('^('+regexAllowedValues.join('|')+')$');
          }  
          if(field.allowed_values_title) this.fields[field.id].allowed_values_title = field.allowed_values_title;
        });

        console.log("["+this.constructor.name+"] Naming Convention loaded", this.namingConvention.version);
  
        this.namingConventionLoading = false;
        this.namingConventionLoaded = true;

        resolve(this.namingConvention);
        
      }).catch(error => {
        console.log("["+this.constructor.name+"] Get Naming Convention error", error.message);
        this.namingConventionLoading = false;
        this.toastrService.error("Failed to load Naming Convention", "Error");
        reject(error.message);
      });
  
    });
  }


  importName(existingName: string) {
    const nameParts = existingName.split('_');

    if(nameParts.length < 6) {
      this.toastrService.warning('This ad name is too short.');
      return;
    }

    _.forEach(this.namingConvention.fields, (field, key) => {
      if(field.id === 'client') {
        // Client field can only sourced from the naming convention
        (this as any)[field.id] = this.client.toUpperCase();
      } else if(field.id === 'free_text') {
        // Manage the optional free_text field (if implemented)
        // (this as any)[field.id] = nameParts[key] || '';
      } else {
        // All other fields
        (this as any)[field.id] = nameParts[key];
      }
    });

    this.generateName();

    if(this.headline) this.retrieveCopyFromMatrix('HDL');
    if(this.primary_text) this.retrieveCopyFromMatrix('PRT');
    if(this.description) this.retrieveCopyFromMatrix('DES');
    if(this.creative_url) this.retrieveCopyFromMatrix('URL');

    if(this.creative) {
      this.retrieveCreativeFromMatrix();
      this.retrieveCreativeFromMeta();
    }  
  }

  generateName(fromField?: string) {
    console.log('Generating name...', fromField ?? '');

    // Build the name using the fields in the naming convention
    this.generatedName = '';
    _.forEach(this.namingConvention.fields, (field, key) => {
      if(field.id === 'free_text') {
        // Manage the optional free_text field (if implemented)
        // if(this.free_text && this.free_text !== '') {
        //   this.generatedName += '_' + this.free_text;
        // } 
      } else {
        // All other fields
        if(key > 0) this.generatedName += '_';
        if((this as any)[field.id]) {
          // console.log('generateName Field', key, field.id, field.name, (this as any)[field.id]);
          this.generatedName += (this as any)[field.id];
        } else {
          // console.log('generateName Field', key, field.id, field.name, '[EMPTY]');
          this.generatedName += '***';
        }
      }
    });

    // Toggle between warning (incomplete) and success (complete) alert styles
    this.generatedNameClass = this.validateForm() ? 'alert-success' : 'alert-warning';

    // Update compliance status
    this.generatedNameValid = this.validateForm();

    // Validate the form after generating the name to show any errors in the UI
    this.validateAllFields(this.namingForm);

    // Load values from GS matrix
    if(fromField && fromField === 'headline') {
      this.retrieveCopyFromMatrix('HDL');
    }
    if(fromField && fromField === 'primary_text') {
      this.retrieveCopyFromMatrix('PRT');
    }
    if(fromField && fromField === 'description') {
      this.retrieveCopyFromMatrix('DES');
    }
    if(fromField && fromField === 'creative_url') {
      this.retrieveCopyFromMatrix('URL');
    }
    if(fromField && fromField === 'creative') {
      this.creativeCheckedInMatrix = false;
      this.creativeCheckedInMeta = false;
      this.retrieveCreativeFromMatrix();
      this.retrieveCreativeFromMeta();
    }

    // Close the Create Ad panel
    this.showCreateAd = false;
  }


  validateAllFields(form: NgForm): void {
    if (!form || !form.controls) return;

    Object.keys(form.controls).forEach(field => {
      const control = form.controls[field];
      control.markAsTouched({ onlySelf: true });
      control.updateValueAndValidity(); // Ensures re-evaluation of the field validity
    });
  }

  highlightText(input: string): string {
    return input.replace(/\*\*\*/g, '<b>***</b>');
  }

  retrieveCopyFromMatrix(copy: 'HDL' | 'PRT' | 'DES' | 'URL') {
    let thisCopy;
    let testCopy = false;
    let col;
    let number;
    switch(copy) {
      case 'HDL':
        thisCopy = this.headline;
        testCopy = thisCopy.startsWith('HDL') && thisCopy.length === 6 && thisCopy !== 'HDLXXX';
        col = this.copyMatrixCols['HDL'];
        number = parseInt(thisCopy.match(/\d+/)?.[0] || '', 10);
        break;
      case 'PRT':
        thisCopy = this.primary_text
        testCopy = thisCopy.startsWith('PRT') && thisCopy.length === 6 && thisCopy !== 'PRTXXX';
        col = this.copyMatrixCols['PRT'];
        number = parseInt(thisCopy.match(/\d+/)?.[0] || '', 10);
        break;
      case 'DES':
        thisCopy = this.description
        testCopy = thisCopy.startsWith('DES') && thisCopy.length === 6 && thisCopy !== 'DESXXX';
        number = parseInt(thisCopy.match(/\d+/)?.[0] || '', 10);
        col = this.copyMatrixCols['DES'];
        break;
      case 'URL':
        thisCopy = this.creative_url
        testCopy = thisCopy.startsWith('URL') && thisCopy.length === 6 && thisCopy !== 'URLXXX';
        number = parseInt(thisCopy.match(/\d+/)?.[0] || '', 10);
        col = this.copyMatrixCols['URL'];
        break;
    }
    
    if(testCopy) {
      switch(copy) {
        case 'HDL':
          this.headlineMatrixLoading = true;
          break;
        case 'PRT':
          this.primary_textMatrixLoading = true;
          break;
        case 'DES':
          this.descriptionMatrixLoading = true;
          break;
        case 'URL':
          this.creative_urlMatrixLoading = true;
          break;
      }      
      
      const rowGS = number; // Row number in the Google Sheets matrix
      const colGS = col; // Col number in the Google Sheets matrix
      let optionsGetGSData = {account: this.accountId, type: 'copy', row: rowGS, col: colGS};
      this.getGSData(optionsGetGSData)
      .then((value) => {
        console.log('getGSData value', value);
        switch(copy) {
          case 'HDL':
            this.headlineMatrixLoading = false;
            break;
          case 'PRT':
            this.primary_textMatrixLoading = false;
            break;
          case 'DES':
            this.descriptionMatrixLoading = false;
            break;
          case 'URL':
            this.creative_urlMatrixLoading = false;
            break;
        }
        if(value) {
          let matrixValue = value.replace(/\n/g, '<br>');
          switch(copy) {
            case 'HDL':
              this.headlineMatrixValue = matrixValue;
              break;
            case 'PRT':
              this.primary_textMatrixValue = matrixValue;
              break;
            case 'DES':
              this.descriptionMatrixValue = matrixValue;
              break;
            case 'URL':
              this.creative_urlMatrixValue = matrixValue;
              break;
          }
        } else {
          switch(copy) {
            case 'HDL':
              this.headlineMatrixValue = null;
              break;
            case 'PRT':
              this.primary_textMatrixValue = null;
              break;
            case 'DES':
              this.descriptionMatrixValue = null;
              break;
            case 'URL':
              this.creative_urlMatrixValue = null;
              break;
          }
          this.toastrService.warning(`${thisCopy} does not exist in the Google Sheets matrix`);
        }
      })
      .catch((error) => {
        console.log('getGSData error', error);
        switch(copy) {
          case 'HDL':
            this.headlineMatrixValue = null;
            this.headlineMatrixLoading = false;
            break;
          case 'PRT':
            this.primary_textMatrixValue = null;
            this.primary_textMatrixLoading = false;
            break;
          case 'DES':
            this.descriptionMatrixValue = null;
            this.descriptionMatrixLoading = false;
            break;
          case 'URL':
            this.creative_urlMatrixValue = null;
            this.creative_urlMatrixLoading = false;
            break;
        }
      });
    } else {
      switch(copy) {
        case 'HDL':
          this.headlineMatrixValue = null;
          break;
        case 'PRT':
          this.primary_textMatrixValue = null;
          break;
        case 'DES':
          this.descriptionMatrixValue = null;
          break;
        case 'URL':
          this.creative_urlMatrixValue = null;
          break;
      }}
  }

  retrieveCreativeFromMatrix() {
    if((this.creative.startsWith('IMG') || this.creative.startsWith('VID') || this.creative.startsWith('CAR')) && this.creative.length === 6 && this.creative !== 'IMGXXX' && this.creative !== 'VIDXXX' && this.creative !== 'CARXXX') {
      this.creativeMatrixLoading = true;
      let optionsGetGSData = {account: this.accountId, type: 'creative', key: this.creative};
      this.getGSData(optionsGetGSData)
      .then((value) => {
        console.log('getGSData value', value);
        this.creativeCheckedInMatrix = true;
        this.creativeMatrixLoading = false;
        // Check that value is not empty and is a valid HTTPS URL
        if(value && value !== '' && value.startsWith('https://')) {
          this.creativeMatrixValue = value;
        } else {
          this.creativeMatrixValue = null;
          this.toastrService.warning(`${this.creative} has not been found in the Google Sheets matrix`);
        }
      })
      .catch((error) => {
        console.log('getGSData error', error);
        this.creativeMatrixLoading = false;
        this.creativeMatrixValue = null;
      });
    } else {
      this.creativeMatrixValue = null;
    }
  }

  retrieveCreativeFromMeta() {
    if((this.creative.startsWith('IMG') || this.creative.startsWith('VID') || this.creative.startsWith('CAR')) && this.creative.length === 6 && this.creative !== 'IMGXXX' && this.creative !== 'VIDXXX' && this.creative !== 'CARXXX') {
      this.creativeMetaThumbnailUrl = null;
      this.creativeMetaThumbnailLoading = true;
      this.searchMetaMediaLibrary(this.creative).then((images) => {
        console.log('Images', images);
        this.creativeCheckedInMeta = true
        if(images && images.length > 0) {
          this.creativeMetaThumbnailUrl = images[0].url;
        } else {
          this.toastrService.warning(`No images found for ${this.creative} in the Media Library`);
        }
        this.creativeMetaThumbnailLoading = false;
      })
      .catch((error) => {
        console.log('Error', error);
        this.toastrService.error(error, 'Meta API error');
        this.creativeMetaThumbnailLoading = false;
      });
    } else {
      this.creativeMetaThumbnailUrl = null;
    }
  }
  
  validateForm(): boolean {
    let isValid = true;
    _.forEach(this.namingConvention.fields, (field, key) => {
      // console.log('Testing validation', field.id, field.validation.regex, (this as any)[field.id]);
      if(field.validation.regex) {
        let thisRegexPattern = field.validation.regex.replace('regexPatterns.', '');
        // console.log('Testing regex pattern for', field.id, field.validation.regex, this.regexPatterns[thisRegexPattern], (this as any)[field.id]);
        if(!(new RegExp(this.regexPatterns[thisRegexPattern]).test((this as any)[field.id]))) {
          console.log('[validateForm] Field failed', field.id, field.validation.regex, this.regexPatterns[thisRegexPattern], (this as any)[field.id]);
          isValid = false;
        } else {
          // console.log('Field Validation passed', field.id, field.validation.regex, this.regexPatterns[thisRegexPattern], (this as any)[field.id]);
        }
      } else {
        // console.log('No regex pattern for', field.id);
        if((this as any)[field.id] === '') {
          console.log('[validateForm] Field failed', field.id, 'NO_REGEX_BUT_EMPTY', (this as any)[field.id]);
          isValid = false;
        }
      }
    });   
    if(isValid) {
      console.log('[validateForm] Passed');
    } else {
      console.log('[validateForm] Failed', this.generatedName);
    }

    return isValid;
  }

  copyToClipboard() {
    navigator.clipboard.writeText(this.generatedName).then(() => {
      this.toastrService.success(this.generatedName, 'Name copied to clipboard!');
    }).catch(err => {
      console.error('Could not copy text: ', err);
    });
  }

  createAdOpen() {
    this.showCreateAd = true;
    this.showRenameAd = false;
    this.getPageIds();    
    this.getImages();
  }

  renameAdOpen() {
    this.showRenameAd = true;
    this.showCreateAd = false;
  }

  renameAd() {
    return new Promise<string>((resolve, reject) => {
      this.loadingAdRename = true;
      const adId = encodeURIComponent(this.adId);
      const adName = encodeURIComponent(this.generatedName);
      let request = '/meta?get=ad-rename&id='+adId+'&name='+adName;
      this.apiService.get(request).then((resp) => {
        console.log('renameAd()', resp);
        this.loadingAdRename = false;
        if(resp.data.success === true) this.toastrService.success('The ad has been renamed', 'Success');
        else this.toastrService.error(resp, 'Unknown API response');
      })
      .catch((error) => {
        console.log('Error', error);
        this.toastrService.error(error.message, 'Meta API error');
        this.loadingAdRename = false;
      })   
    });
  }

  createAd() {

    let validatedPostId = this.post_id.replace(/^POSTID/, ''); // Removes "POSTID" only if it appears at the start
    if(this.post_id !== 'POSTIDXXX' && this.post_id !== '') {
      // If a post ID is provided, it must be a number of at least 5 digits
      if(!/^\d{5,}$/.test(validatedPostId)) {
        this.toastrService.error('The Post ID must be a string representing a number of at least 5 digits.');
        return;
      }    
    }

    return new Promise<string>((resolve, reject) => {
      if(this.headlineMatrixValue && this.creative_urlMatrixValue && this.primary_textMatrixValue) {
        this.loadingAdCreate = true;
        const adName = encodeURIComponent(this.generatedName);
        const adHeadline = encodeURIComponent(this.headlineMatrixValue.replace(/<br>/g, '\n'));
        const adPrimaryText = encodeURIComponent(this.primary_textMatrixValue.replace(/<br>/g, '\n'));
        const adUrl = encodeURIComponent(this.creative_urlMatrixValue);
        const adDescription = this.descriptionMatrixValue ? encodeURIComponent(this.descriptionMatrixValue.replace(/<br>/g, '\n')) : '';
        const adUrlTags = encodeURIComponent(this.creativeUrlTags);

        // 1. Create a new creative
        console.log('createAd() - creative-create calling', this.account_id, adHeadline, this.selectedImageHash, adUrl, adPrimaryText, adDescription, validatedPostId, adUrlTags);
        let request = `/meta?get=creative-create&acc_id=${this.account_id}&name=${adHeadline}&image_hash=${this.selectedImageHash}&url=${adUrl}&message=${adPrimaryText}&description=${adDescription}&page_id=${this.selectedPageId}&url_tags=${adUrlTags}`;
        this.apiService.get(request)
        .then((resp) => {
          console.log('createAd() - creative-create response', resp);
          if(resp.data.success == true) {
            this.selectedCreativeId = resp.data.creative_id;
            // 2. Create an ad
            console.log('createAd() - ad-create', this.account_id, adName, this.adsetId, this.selectedCreativeId);
            let request = `/meta?get=ad-create&name=${adName}&adset_id=${this.adsetId}&creative_id=${this.selectedCreativeId}&acc_id=${this.account_id}`;
            this.apiService.get(request).then((resp) => {
              console.log('createAd()', resp);
              this.loadingAdCreate = false;
              if(resp.data.success === true) {
                this.toastrService.success(`The ad has been created [${resp.data.ad_id}]`, 'Success');
                this.adId = resp.data.ad_id;
                this.urlCreatedAd = `https://adsmanager.facebook.com/adsmanager/manage/ads/edit?act=${this.account_id}&selected_ad_ids=${this.adId}&breakdown_regrouping=1&nav_source=no_referrer&current_step=0`;
              }  
              else {
                this.toastrService.error(resp, 'Unknown API response. Please check Ads Manager.');
              }  
            })
            .catch((error) => {
              console.log('Error', error);
              this.toastrService.error(error.message, 'Meta API error');
              this.loadingAdCreate = false;
            });
          } else {
            // Failed to create the ad
            this.loadingAdCreate = false;
            this.toastrService.error(resp, 'Unknown API response. Please check Ads Manager.');
          }
        })
        .catch((error) => {
          // Failed to create the creative          
          console.log('Error', error);
          this.toastrService.error(error.message, 'Meta API error');
          this.loadingAdCreate = false;
        });
        
      } else {
        this.toastrService.error('Missing HDL/PRT/URL');
      }
    });
  }

  toggleImportName() {
    this.showImportName = !this.showImportName;
  }

  getGSData(options:{account: string, type: string, row?: number, col?: number, key?: string, key_col?: number}) {
    return new Promise<string>((resolve, reject) => {
      let request = `/google-sheets?get=matrix-cell&account=${options.account}&type=${options.type}`;
      if(options.type === 'copy') {
        request += `&row=${options.row}&col=${options.col}`;
      } else if(options.type === 'creative') {
        request += `&key=${options.key}`;
      } else {
        reject('Invalid type');
      }
      this.apiService.get(request).then((response) => {
        console.log('getGSData response', response);
        resolve(response.data);        
      })
      .catch((error) => {
        console.log('Error', error);
        this.toastrService.error(error.message, 'Google Sheets API error');
        reject(error.message);
      })   
    });

  }

  searchMetaMediaLibrary(name: string) {
    return new Promise<Image[]>((resolve, reject) => {
      let request = '/meta?get=account-images&acc_id='+this.account_id+'&search='+name;
      this.apiService.get(request).then((resp) => {
        console.log('searchMetaMediaLibrary()', resp);
        resolve(resp.data);
      })
      .catch((error) => {
        console.log('Error', error);
        this.toastrService.error(error.message, 'Meta API error');
        reject(error.message);
      })   
    });
  }

  // getAdsetCreatives() {
  //   return new Promise<Creative[]>((resolve, reject) => {
  //     this.adsetCreativesLoading = true;
  //     let request = '/meta?get=adset-creatives&adset_id='+this.adsetId;
  //     this.apiService.get(request).then((resp) => {
  //       console.log('getAdsetCreatives', resp);
  //       this.adsetCreativesLoading = false;
  //       let adsetCreatives: Creative[] = [];
  //       _.forEach(resp.data.creatives, (creative: any) => {
  //         adsetCreatives.push({
  //           id: creative.id,
  //           name: creative.name,
  //           thumbnail_url: creative.thumbnail_url,
  //           title: creative.title,
  //           text: creative.text,
  //           link_url: creative.link_url,
  //           call_to_action_type: creative.call_to_action_type, 
  //           page_id: creative.page_id,
  //           story_id: creative.story_id
  //         });
  //       });
  //       this.adsetCreatives = adsetCreatives;
  //       console.log('Adset Creatives', adsetCreatives);
  //       resolve(adsetCreatives);
  //     })
  //     .catch((error) => {
  //       console.log('Error', error);
  //       this.adsetCreativesLoading = false;
  //       this.toastrService.error(error.message, 'Meta API error');
  //       reject(error.message);
  //     })   
  //   });
  // }

  getImages() {
    return new Promise<Image[]>((resolve, reject) => {
      this.imagesloading = true;
      this.searchMetaMediaLibrary(this.creative)
      .then((imgs) => {
        this.imagesloading = false;
        console.log('Images', imgs);
        let images: Image[] = [];
        _.forEach(imgs, (image: any, index) => {
          images.push({
            id: image.id,
            name: image.name,
            url: image.url,
            hash: image.hash
          });
          if(index === 0) this.selectedImageHash = image.hash;
        });
        this.images = images
        resolve(images);
      })
      .catch((error) => {
        this.imagesloading = false;
        console.log('Error', error);
        // this.toastrService.error(error.message, 'Meta API error');
        reject(error.message);
      })   
    });
  }

  getPageIds() {
    return new Promise<Page[]>((resolve, reject) => {
      this.pageIdsLoading = true;
      let request = '/meta?get=account-pageids&acc_id='+this.account_id;
      this.apiService.get(request).then((resp) => {
        console.log('getPageIds()', resp);
        this.pageIdsLoading = false;
        let pages: Page[] = [];
        _.forEach(resp.data, (page: any, index) => {
          // console.log('getPageIdsindex', index);
          pages.push({
            id: page.id,
            name: page.name
          });
          if(index == '0') this.selectedPageId = page.id;
        });
        this.pageIds = pages;
        console.log('Page IDs', pages);
        resolve(pages);
      })
      .catch((error) => {
        console.log('Error', error);
        this.pageIdsLoading = false;
        this.toastrService.error(error.message, 'Meta API error');
        reject(error.message);
      })   
    });
  }

  resetFieldToDefault(field: string) {
    switch(field) {
      case 'exclusion_type':
        this.exclusion_type = 'EXLXXX';
        break;
      case 'audience_type':
        this.audience_type = 'BROAD';
        break;
      case 'adset_test_id':
        this.adset_test_id = 'XXXTSTXXX';
        break;
      case 'creative':
        this.creative = 'IMGXXX';
        this.creativeCheckedInMatrix = false;
        this.creativeCheckedInMeta = false;
        this.creativeMatrixValue = null;
        this.creativeMetaThumbnailUrl = null;
        break;
      case 'headline':
        this.headline = 'HDLXXX';
        this.headlineMatrixValue = null;
        break;
      case 'primary_text':
        this.primary_text = 'PRTXXX';
        this.primary_textMatrixValue = null;
        break;
      case 'description':
        this.description = 'DESXXX';
        this.descriptionMatrixValue = null;
        break;
      case 'creative_url':
        this.creative_url = 'URLXXX';
        this.creative_urlMatrixValue = null;
        break;
      case 'post_id':
        this.post_id = 'POSTIDXXX';
        break;
    }
    
    this.generateName();
  }

  openModalAdset(content: any) {
		this.modalService.open(content, { size: 'lg', scrollable: true });
	}

  selectAdset(adset: {id: string, name: string}) {
    console.log('Selected Adset', adset);
    if(adset.id && adset.name) {
      this.adsetId = adset.id;
      this.adsetName = adset.name;
    }
  }

  // Get list of account adsets from the server
  getAccountAdsets() {
    return new Promise<{id: string, name: string, created_time: string, campaign_name: string}[]>((resolve, reject) => {
      this.accountAdsetsLoading = true;
      let request = '/meta?get=account-adsets&acc_id='+this.account_id;
      this.apiService.get(request).then((resp) => {
        console.log('getAccountAdsets()', resp);
        this.accountAdsetsLoading = false;
        let adsets: {id: string, name: string, created_time: string, campaign_name: string}[] = [];
        _.forEach(resp.data, (adset: any) => {
          adsets.push({
            id: adset.id,
            name: adset.name,
            created_time: adset.created_time,
            campaign_name: adset.campaign.name
          });
        });
        this.accountAdsets = adsets;
        // console.log('Account Adsets', adsets);
        resolve(adsets);
      })
      .catch((error) => {
        console.log('Error', error);
        this.accountAdsetsLoading = false;
        this.toastrService.error(error.message, 'Meta API error');
        reject(error.message);
      })   
    });
  }

  
}
