import { Component, OnInit, ViewChild, inject } from '@angular/core';
import { RouterLink, Router, ActivatedRoute, Params } from '@angular/router';
import { AuthService } from '../../../services/auth.service';
import { CommonModule } from '@angular/common';
import { ApiService } from '../../../services/api.service';
import { AdAccount } from '../../../interfaces/ad-account';
import { ToastrService } from 'ngx-toastr';
import * as _ from 'lodash';
import { HttpClient, HttpEventType, HttpHeaders } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { FileUploadComponent } from './file-upload.component';
import { CreativeUploadFile } from '../../../interfaces/creative';

@Component({
  selector: 'app-creative-uploader',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    FormsModule,
    NgbTooltipModule,
    FileUploadComponent  
  ],
  templateUrl: './creative-uploader.component.html',
  styleUrl: './creative-uploader.component.css'
})
export class CreativeUploaderComponent implements OnInit {
  activatedRoute: ActivatedRoute = inject(ActivatedRoute);
  router: Router = inject(Router);
  authService: AuthService = inject(AuthService);
  apiService: ApiService = inject(ApiService);
  toastrService: ToastrService = inject(ToastrService);
  http: HttpClient = inject(HttpClient);

  // User Authentication
  authenticatedUser = this.authService.authenticatedUser;

  // Component-specific variables
  googleCredentialsEmail: string | null = null;

  accountId: string = '';
  adAccount: AdAccount | null = null;

  loadindAdAccount = true;

  gdCreativeFolderId = '';
  metaToken = '';
  creativeType: 'IMG' | 'VID' | 'CAR' = 'IMG';
  creativeId = ''; // IMG001, VID001 or CAR001
  creativeIdLoading = false;
  creativeIdLoadingProgress = 0;
  @ViewChild('fileUpload') fileUpload!: FileUploadComponent;
  fileNameHubbleSuffix = '_HBL';
  creativeFiles: CreativeUploadFile[] = [];

  ngOnInit(): void {
    // Initialise upload files
    this.resetForm();
    
    // Check that user is authenticated (should be the case if this path is protected with AuthGuardService in app.routes.ts)
    this.authService.checkUserSignedIn().then(checkSign => {
      if (!checkSign) {
        // User is NOT signed in => Call AuthService.signOut() to redirect to Sign in page
        this.authService.signOut();
      } else {
        // User is signed in
        // Get Ad Account ID from URL params
        this.activatedRoute.params.subscribe((params: Params) => {
          this.accountId = params['id'];
          console.log(`Loading Ad Account#: ${this.accountId}`);
          // Check if the user has access to the Ad Accounts section & this specific ad account
          this.authService.checkUserHasAccessToAdAccounts().then(adAccounts => {
            if(adAccounts === null || !adAccounts.some(adAccount => adAccount.id === this.accountId)) {
              // User does not have access to the account (or the user has no ad accounts at all)
              console.log("User does not have access to this ad account");
              this.toastrService.error("You do not have access to this Ad Account", "Access Denied");
              this.router.navigate(['/']);
            } else {
              // User has access to the account
              console.log("User has access to this ad account");

              // Get user's Meta token from the server
              let request = `/users?get=meta-token`;
              this.apiService.get(request)
              .then(response => {
                console.log("[Meta token] Get response", response);
                this.metaToken = response.data.access_token;
              }).catch(error => {
                console.log("[Meta token] Get error", error);
                this.toastrService.error(error.message, 'Failed to load Meta token');
              });

              // Get ad account details from the server
              this.loadindAdAccount = true;
              let requestPath = `/ad-accounts?get=${this.accountId}`;
              this.apiService.get(requestPath)
              .then(response => {
                console.log("[Ad account details] Get response", response);  
                this.loadindAdAccount = false; 
                this.adAccount = {
                  id: this.accountId, 
                  name: response.data.name,
                  meta_id: response.data.meta_id,
                  gs_ads_library_id: response.data.gs_ads_library_id,
                  gd_creatives_folder_id: response.data.gd_creatives_folder_id
                };
                if(!response.data.meta_id) this.toastrService.error('No Meta ID found for this Ad Account', 'Error');
                if(!response.data.gs_ads_library_id) this.toastrService.error('No Google Sheet Ads Library ID found for this Ad Account', 'Error');
                if(!response.data.gd_creatives_folder_id) this.toastrService.error('No Google Drive Creatives Folder ID found for this Ad Account', 'Error');
              }).catch(error => {
                console.log("[Ad account details] Get error", error);
                this.loadindAdAccount = false;
                this.toastrService.error(error.message, 'Failed to load Ad Account');
              });

              // Get the Google Credentials email
              this.getGoogleCredentialsEmailAddress()
              .then((email) => {
                this.googleCredentialsEmail = email;
              })
              .catch((error) => {
                console.log('Google credentials email error', error);
              });

              // _.forEach(this.creativeFiles, (creativeFile) => {
              //   console.log(creativeFile);
              // });

            }        
          });
        });
      }  
    });
  }


  // Function getGoogleCredentialsEmailAddress() to get the email of the Google credentials from the server
  getGoogleCredentialsEmailAddress() {
    return new Promise<string>((resolve, reject) => {
      let request = `/google-drive?get=credentials-email`;
      this.apiService.get(request)
      .then((data) => {
        console.log('Google credentials email', data);
        resolve(data.data);
      })
      .catch((error) => {
        console.log('Error', error);
        reject(error);
      });
    });
  }

  triggerFileInputClick() {
    this.fileUpload.triggerFileInputClick();
  }

  onFileReady(event: {id: string, file: File | null}) {
    console.log('onFileReady() triggered', event.id, event.file);
    // Get the index of this file in the creativeFiles array
    const indexFile = this.creativeFiles.findIndex((creativeFile) => creativeFile.id === event.id);
    if(this.creativeFiles[indexFile]) {
      this.creativeFiles[indexFile].selected_file = event.file;
      this.renameFile(event.id);
    }    
  }

  onFileError(event: {id: string, error: string | null}) {
    console.log('onFileError() triggered', event.id, event.error);
    console.error('File error:', event.error);
    this.toastrService.error(event.error?.toString() ?? 'N/A', 'File error');
  }

  renameFile(id: string) {
      console.log('renameFile', id);
      // Get the index of this file in the creativeFiles array
      const indexFile = this.creativeFiles.findIndex((creativeFile) => creativeFile.id === id);
      if(indexFile >= 0 && this.creativeFiles[indexFile]) {
        let fileName = this.creativeId + '_' + id.toUpperCase() + this.fileNameHubbleSuffix;
        if(fileName.includes('.')) {
          fileName = fileName.split('.').slice(0, -1).join('.');
        }
        const extension = this.creativeFiles[indexFile].selected_file?.name.split('.').pop();
        this.creativeFiles[indexFile].name = fileName + '.' + (extension ?? '');
      }
  }

  startUpload(id: string) {
    // Get the index of this file in the creativeFiles array
    const indexFile = this.creativeFiles.findIndex((creativeFile) => creativeFile.id === id);
    const selectedFile = this.creativeFiles[indexFile].selected_file;
    if (!selectedFile) {
      // No file selected => Do nothing
      console.log('No file selected');
      return;
    } else {
      // File selected => Start upload
      console.log('Starting upload:', selectedFile);

      // Get the index of this file in the creativeFiles array
      const indexFile = this.creativeFiles.findIndex((creativeFile) => creativeFile.id === id);

      this.creativeFiles[indexFile].status = 'uploading';
      this.creativeFiles[indexFile].upload_progress = 0;
      this.creativeFiles[indexFile].uploaded = { gdrive: null, meta: false };
      this.creativeFiles[indexFile].ads_library_updated = false;

      // Check that uploadFileName is not null, its extension is either jpg, png, gif, or mp4 and it does not have XXX in it
      if(!this.creativeFiles[indexFile].name || !this.creativeFiles[indexFile].name.includes('.') || !['jpg', 'png', 'gif', 'mp4'].includes(this.creativeFiles[indexFile].name.split('.').pop()!.toLowerCase()) || this.creativeFiles[indexFile].name.includes('XXX')) {
        this.toastrService.error('Invalid file name', 'Upload error');
        return;
      }
      
      const formData = new FormData();
      formData.append('file', selectedFile);

      // Step 1: Get a pre-signed S3 URL to upload the file directly to S3 
      let request = `/google-drive?get=s3-uploadurl&file_type=${selectedFile.type}&file_name=${selectedFile.name}`;
      let oFileName = '';
      let s3FileName = '';
      this.creativeFiles[indexFile].upload_progress = 10;
      console.log('Getting S3 signed URL', request);
      this.apiService.get(request).then((resp) => {
        console.log('S3 signed URL retrieved', resp.data);
        this.creativeFiles[indexFile].upload_progress = 30;
        if(resp.data && resp.data.url) {
          let url = resp.data.url;
          oFileName = resp.data.o_name;
          s3FileName = resp.data.t_name;
          // Step 2: Upload the file to S3 using the pre-signed URL
          const headers = new HttpHeaders({
            'Content-Type': selectedFile.type
          });
          console.log('Uploading file to S3', url);
          this.http.put(url, selectedFile, { headers })
          .subscribe({
            next: (response) => {
              console.log('S3 response (usually empty)', response);
              // Step 3: Check if a Google Drive folder with the creative ID exists, if not create one
              this.creativeFiles[indexFile].upload_progress = 45;
              this.getOrCreateGDFolderForName(this.creativeId)
              .then((folderId) => {
                console.log('Google Drive folder ID', folderId);
                this.gdCreativeFolderId = folderId;
                // Step 3: Copy the file from S3 to Google Drive
                request = `/google-drive?get=s3-gdrive&t_name=${s3FileName}&o_name=${this.creativeFiles[indexFile].name}&gdfid=${this.gdCreativeFolderId}`;
                this.apiService.get(request)
                .then((respGDrive) => {
                  console.log('Google Drive upload completed', respGDrive);
                  this.creativeFiles[indexFile].uploaded.gdrive = respGDrive.data.id;
                  this.creativeFiles[indexFile].upload_progress = 66;
                  // Step 4: Update creative URL in the Google Sheet
                  request = `/google-sheets?get=adslibrary-update-creaurl&account=${this.accountId}&creative_id=${this.creativeId}&url=https://drive.google.com/drive/folders/${this.gdCreativeFolderId}`;
                  this.apiService.get(request)
                  .then((respGSheets) => {
                    console.log('Creative URL updated', respGSheets);
                      this.creativeFiles[indexFile].ads_library_updated = true;
                    this.creativeFiles[indexFile].upload_progress = 80;
                    // Step 5: Copy the file from S3 to Meta
                    request = `/google-drive?get=s3-meta&t_name=${s3FileName}&o_name=${this.creativeFiles[indexFile].name}&type=${this.creativeType == 'VID' ? 'video' : 'image'}&account=${this.accountId}&token=${this.metaToken}`;
                    this.apiService.get(request)
                    .then((respMeta) => {
                      console.log('Meta upload completed', respMeta);
                      this.creativeFiles[indexFile].uploaded.meta = true;
                      this.creativeFiles[indexFile].upload_progress = 100;
                      // Wait 0.5 second before setting creativeLoading to false (to finalise the progress bar animation)
                      console.log('File uploaded & creative updated successfully', this.creativeFiles[indexFile]);
                      setTimeout(() => {
                        this.creativeFiles[indexFile].status = 'uploaded';
                      }, 500);
                      this.toastrService.success('File uploaded & creative URL updated successfully', 'Upload success');
                    })
                    .catch((error) => {
                      console.log('Error', error);
                      this.creativeFiles[indexFile].status = 'error';
                      this.toastrService.error(error.message, 'Meta Upload error', { disableTimeOut: true });
                    });
                  })
                  .catch((error) => {
                    console.log('Error', error);
                    this.creativeFiles[indexFile].status = 'error';
                    this.toastrService.error(error.message, 'Ads Library URL update error', { disableTimeOut: true });
                  });
                })
                .catch((error) => {
                  console.log('Error', error);
                  this.creativeFiles[indexFile].status = 'error';
                  this.toastrService.error(error.message, 'Google Drive Upload error', { disableTimeOut: true });
                });  
              })
              .catch((error) => {
                console.log('Error', error);
                this.creativeFiles[indexFile].status = 'error';
                this.toastrService.error(error.message, 'Google Drive Folder error', { disableTimeOut: true });
              });                                                           
            },
            error: (error) => {
              console.error('Upload error:', error);
              this.creativeFiles[indexFile].status = 'error';
              this.toastrService.error(error.message, 'Upload error', { disableTimeOut: true });
            }
          });  
        } else {
          this.creativeFiles[indexFile].status = 'error';
          this.toastrService.error('Failed to initiate upload', 'S3 Upload error', { disableTimeOut: true });
          return;
        }      
      })
      .catch((error) => {
        console.log('Error', error);
        this.creativeFiles[indexFile].status = 'error';
        this.toastrService.error(error.message, 'Upload error', { disableTimeOut: true });
      });    
    }
  }

  getOrCreateGDFolderForName(name: string): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      let request = `/google-drive?get=check-item&name=${name}&parent=${this.adAccount?.gd_creatives_folder_id}&type=folder`;
      console.log('Checking if creative folder exists in Google Drive', name, this.adAccount?.gd_creatives_folder_id);
      this.apiService.get(request)
      .then((resp) => {
        console.log('Check folder', resp);
        if(resp.data.exists) {
          // Folder exists
          console.log('Folder exists', resp.data.item);
          // Return the existing folder ID
          resolve(resp.data.item.id);
        } else {
          // Folder does not exist
          console.log('Folder does not exist, creating it', resp);
          request = `/google-drive?get=create-folder&name=${name}&id=${this.adAccount?.gd_creatives_folder_id}`;
          this.apiService.get(request)
          .then((response) => {
            console.log('Folder created', response);
            // Return the new folder ID
            resolve(response.data.id);
          })
          .catch((error) => {
            console.log('Error creating folder', error);
            reject(error);
          });
        }
      })
      .catch((error) => {
        console.log('Error', error);
        reject(error);
      });
    });
  }


  createNewCreativeId() {
    return new Promise<string>((resolve, reject) => {
      let newId: string;
      // Get a new creative ID from the Ad Libray Google Sheet
      console.log('Getting new creative ID from the Ad Libray Google Sheet', this.adAccount?.meta_id);
      this.creativeIdLoadingProgress = 10;
      let request = `/google-sheets?get=adslibrary-newid&account=${this.accountId}&type=${this.creativeType.toLowerCase()}`;
      this.apiService.get(request)
      .then((data) => {
        console.log('New creative ID', data.data.id);
        newId = data.data.id;
        this.creativeIdLoadingProgress = 33;
        // Check if a Google Drive folder with the new creative ID already exists, if not create one
        console.log('Checking if creative folder exists in Google Drive', newId);
        request = `/google-drive?get=check-item&name=${newId}&parent=${this.adAccount?.gd_creatives_folder_id}&type=folder`;
        this.apiService.get(request)
        .then((resp) => {
          console.log('Check folder', resp);
          this.creativeIdLoadingProgress = 66;
          if(resp.data.exists) {
            // Folder exists
            console.log('Folder exists', resp.data.item);
            this.gdCreativeFolderId = resp.data.item.id;
            this.creativeIdLoadingProgress = 100;
            resolve(newId);
          } else {
            // Folder does not exist
            console.log('Folder does not exist, creating it', resp);
            request = `/google-drive?get=create-folder&name=${newId}&id=${this.adAccount?.gd_creatives_folder_id}`;
            this.apiService.get(request)
            .then((response) => {
              console.log('Folder created', response);
              this.gdCreativeFolderId = response.data.id;
              this.creativeIdLoadingProgress = 100;
              resolve(newId);
            })
            .catch((error) => {
              console.log('Error creating folder', error);
              reject(error);
            });
          }
        })
      })
      .catch((error) => {
        console.log('Error', error);
        reject(error);
      });
    });
  }

  getNewCreativeId() {
    this.creativeIdLoading = true;
    this.createNewCreativeId().then((newId) => {
      this.creativeId = newId;
      // Wait 1 second before setting creativeLoading to false (to finalise the progress bar animation)
      setTimeout(() => {
        this.creativeIdLoading = false;
      }, 1000);
      this.onCreativeIdBlur();
      this.onCreativeIdChange();
      this.toastrService.success('New creative ID generated', 'Success');
    })
    .catch((error) => {
      this.toastrService.error(error.message, 'Error');
      this.creativeIdLoading = false;
    });
  }

  onCreativeIdChange() {
    console.log('onCreativeIdChange() triggered', this.creativeId);
    // If all creative files have been uploaded, reset the form
    if(this.creativeFiles.every((creativeFile) => creativeFile.status === 'uploaded')) {
      this.resetForm();
    }    
    
  }  

  onCreativeTypeChange(type: 'IMG' | 'VID' | 'CAR') {
    console.log('onCreativeTypeChange() triggered', type);
    this.creativeType = type;
    this.creativeId = '';
    this.resetForm();
  }

  onCreativeIdBlur() {
    console.log('onCreativeIdBlur() triggered', this.creativeId);
    // Change the upload file names when the creative ID changes but keep the extension
    _.forEach(this.creativeFiles, (creativeFile) => {
      console.log('onCreativeIdBlur() processing', creativeFile);
      this.renameFile(creativeFile.id);
    });
  }

  // Function to let the user upload new files to a new creative ID
  resetForm() {
    this.creativeId = '';
    this.creativeIdLoading = false;
    this.creativeIdLoadingProgress = 0;
    this.gdCreativeFolderId = '';
    switch(this.creativeType) {
      case 'IMG':
        this.creativeFiles = [
          { id: 'FEED', name: '', type: 'IMG', url: '', selected_file: null, style: 'feed', upload_progress: 0, status: 'pending', uploaded: { gdrive: null, meta: false }, ads_library_updated: false },
          { id: 'STORY', name: '', type: 'IMG', url: '', selected_file: null, style: 'story', upload_progress: 0, status: 'pending', uploaded: { gdrive: null, meta: false }, ads_library_updated: false }
        ];
        break;
      case 'VID':
        this.creativeFiles = [
          { id: 'FEED', name: '', type: 'VID', url: '', selected_file: null, style: 'feed', upload_progress: 0, status: 'pending', uploaded: { gdrive: null, meta: false }, ads_library_updated: false },
          { id: 'STORY', name: '', type: 'VID', url: '', selected_file: null, style: 'story', upload_progress: 0, status: 'pending', uploaded: { gdrive: null, meta: false }, ads_library_updated: false }
        ];
        break;
      case 'CAR':
        this.creativeFiles = [
          { id: '01_FEED', name: '', type: 'CAR', url: '', selected_file: null, style: 'feed', upload_progress: 0, status: 'pending', uploaded: { gdrive: null, meta: false }, ads_library_updated: false },
          { id: '01_STORY', name: '', type: 'CAR', url: '', selected_file: null, style: 'story', upload_progress: 0, status: 'pending', uploaded: { gdrive: null, meta: false }, ads_library_updated: false },
          { id: '02_FEED', name: '', type: 'CAR', url: '', selected_file: null, style: 'feed', upload_progress: 0, status: 'pending', uploaded: { gdrive: null, meta: false }, ads_library_updated: false },
          { id: '02_STORY', name: '', type: 'CAR', url: '', selected_file: null, style: 'story', upload_progress: 0, status: 'pending', uploaded: { gdrive: null, meta: false }, ads_library_updated: false },
          { id: '03_FEED', name: '', type: 'CAR', url: '', selected_file: null, style: 'feed', upload_progress: 0, status: 'pending', uploaded: { gdrive: null, meta: false }, ads_library_updated: false },
          { id: '03_STORY', name: '', type: 'CAR', url: '', selected_file: null, style: 'story', upload_progress: 0, status: 'pending', uploaded: { gdrive: null, meta: false }, ads_library_updated: false },
          { id: '04_FEED', name: '', type: 'CAR', url: '', selected_file: null, style: 'feed', upload_progress: 0, status: 'pending', uploaded: { gdrive: null, meta: false }, ads_library_updated: false },
          { id: '04_STORY', name: '', type: 'CAR', url: '', selected_file: null, style: 'story', upload_progress: 0, status: 'pending', uploaded: { gdrive: null, meta: false }, ads_library_updated: false },
          { id: '05_FEED', name: '', type: 'CAR', url: '', selected_file: null, style: 'feed', upload_progress: 0, status: 'pending', uploaded: { gdrive: null, meta: false }, ads_library_updated: false },
          { id: '05_STORY', name: '', type: 'CAR', url: '', selected_file: null, style: 'story', upload_progress: 0, status: 'pending', uploaded: { gdrive: null, meta: false }, ads_library_updated: false },
          { id: '06_FEED', name: '', type: 'CAR', url: '', selected_file: null, style: 'feed', upload_progress: 0, status: 'pending', uploaded: { gdrive: null, meta: false }, ads_library_updated: false },
          { id: '06_STORY', name: '', type: 'CAR', url: '', selected_file: null, style: 'story', upload_progress: 0, status: 'pending', uploaded: { gdrive: null, meta: false }, ads_library_updated: false },
        ];
        break;
    }
    console.log('Form reset', this.creativeFiles);
  }

  // Function to let the user add a new item to the creativeFiles array
  addCreativeFile() {
    console.log('addCreativeFile() triggered');
    let newId = (this.creativeFiles.length + 1).toString();
    this.creativeFiles.push({ id: newId, name: '', type: this.creativeType, url: '', selected_file: null, upload_progress: 0, status: 'pending', uploaded: { gdrive: null, meta: false }, ads_library_updated: false });
    console.log('Creative file added', this.creativeFiles);
  }

}
