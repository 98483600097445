<div class="container">

  <div class="row" style="margin: 0 30px;">
      <div class="col">
          <nav style="margin-bottom: 50px; --bs-breadcrumb-divider: url(&#34;data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='currentColor'/%3E%3C/svg%3E&#34;); margin-bottom: 30px;" aria-label="breadcrumb">
              <ol class="breadcrumb">
                  <li class="breadcrumb-item"><a routerLink="/ad-accounts">Ad Accounts</a></li>
                  <li class="breadcrumb-item"><a routerLink="/ad-accounts/{{ namingConvention.id }}">{{ namingConvention.id | titlecase }}</a></li>
                  <li class="breadcrumb-item active" aria-current="page">Naming Convention Generator</li>
              </ol>
          </nav>

          <div *ngIf="namingConventionLoading" style="color: grey;">
            <span class="spinner-border text-dark" role="status" aria-hidden="true" style="vertical-align: middle; margin-right: 10px;"></span>Loading the Naming Convention<span class="ellipsisAnimated"></span>
          </div>

          <div *ngIf="namingConventionLoaded">

            <div (click)="openModalSelectAdset()" class="row align-items-center" style="margin-bottom: 10px; cursor: pointer;">
              <div class="col-auto">
                <div class="input-group">
                  <div class="input-group-text" style="background-color: #212529; color: white; font-family: 'Gelica', serif; font-weight: bold;">Ad Set</div>
                  <div class="form-control border d-flex align-items-center" style="font-size: 85%;">
                    <span *ngIf="adsetId != ''" class="text-break">{{ adsetName ? adsetName : adsetId }} </span>    
                    <a *ngIf="adsetId == ''" href="javascript:void(0);">Select an Ad Set</a>                   
                  </div>                            
                </div>
              </div>
              <div class="col"></div>
            </div>  
            
            <div style="text-align: right; margin-bottom: 5px;">
              <span *ngIf="!showImportName" (click)="toggleImportName()" class="btn btn-sm btn-dark" style="cursor: pointer;">Import a Name</span>
              <span *ngIf="!importAdFromMetaLoading" (click)="openModal(modalImportAd)" class="btn btn-sm btn-dark" style="cursor: pointer; margin-left: 10px;">Import an Ad</span>
              <span *ngIf="importAdFromMetaLoading" class="spinner-border spinner-border-sm text-primary" style="margin-left: 10px;" role="status"><span class="visually-hidden">Loading...</span></span>
            </div>
            <div *ngIf="showImportName" class="row mb-3 align-items-center" style="border: 1px solid grey; border-radius: 5px; padding: 10px 0; background-color: #e4f2fe; margin-bottom: 15px; margin-top: 15px;">
              <div class="col">
                  <input type="text" id="existingName" [(ngModel)]="existingName" class="form-control" [placeholder]="placeholderImportName" (keydown.enter)="importName(existingName)">
              </div>
              <div class="col" style="max-width: 120px;">
                <button type="button" class="btn btn-dark" (click)="importName(existingName)">Import</button>                  
              </div>
            </div>
 
              <form #namingForm="ngForm">
  
                <!-- Campaign Launch Date -->
                <div class="row mb-3 align-items-center">
                  <div class="col-2">
                    <label for="campaign_launch_date" class="form-label">{{ fields['campaign_launch_date'].name }}</label>
                  </div>
                  <div class="col-3">
                    <input 
                      type="text" 
                      id="campaign_launch_date" 
                      [(ngModel)]="campaign_launch_date" 
                      name="campaign_launch_date" 
                      class="form-control" 
                      required 
                      [pattern]="fields['campaign_launch_date'].regex"
                      [placeholder]="fields['campaign_launch_date'].placeholder"
                      (ngModelChange)="generateName()"
                      #campaign_launch_dateField="ngModel">
                    <div *ngIf="campaign_launch_dateField.invalid && campaign_launch_dateField.touched" class="text-danger">
                      <i class="bi bi-exclamation-circle"></i> {{ fields['campaign_launch_date'].error }}
                    </div>
                  </div>
                  <div class="col-1" style="text-align: center;">                                   
                  </div>
                  <div class="col-6">
                    <p class="text-description">{{ fields['campaign_launch_date'].description }}</p>
                  </div>
                </div>
                
                <!-- Campaign Client -->
                <div class="row mb-3 align-items-center">
                  <div class="col-2">
                    <label for="campaign_client" class="form-label">{{ fields['campaign_client'].name }}</label>
                  </div>
                  <div class="col-3">
                    <input 
                      type="text" 
                      id="campaign_client" 
                      [(ngModel)]="campaign_client" 
                      name="campaign_client" 
                      class="form-control bg-light" 
                      readonly 
                      [value]="namingConvention.id">
                  </div>
                  <div class="col-1" style="text-align: center;">            
                  </div>
                  <div class="col-6">
                    <p class="text-description">{{ fields['campaign_client'].description }}</p>
                  </div>
                </div>
  
                <!-- Campaign Unique ID -->
                <div class="row mb-3 align-items-center">
                  <div class="col-2">
                    <label for="campaign_unique_id" class="form-label">{{ fields['campaign_unique_id'].name }}</label>
                  </div>
                  <div class="col-3">
                    <input 
                      type="text" 
                      id="campaign_unique_id" 
                      [(ngModel)]="campaign_unique_id" 
                      name="campaign_unique_id" 
                      class="form-control" 
                      required 
                      [pattern]="fields['campaign_unique_id'].regex"
                      [placeholder]="fields['campaign_unique_id'].placeholder"
                      (ngModelChange)="generateName()"
                      #campaign_unique_idField="ngModel">
                    <div *ngIf="campaign_unique_idField.invalid && campaign_unique_idField.touched" class="text-danger">
                      <i class="bi bi-exclamation-circle"></i> {{ fields['campaign_unique_id'].error }}
                    </div>
                  </div>
                  <div class="col-1" style="text-align: center;">
                  </div>
                  <div class="col-6">
                    <p class="text-description">{{ fields['campaign_unique_id'].description }}</p>
                  </div>
                </div>
  
                <!-- Campaign Phase -->
                <div class="row mb-3 align-items-center">
                  <div class="col-2">
                    <label for="campaign_phase" class="form-label">{{ fields['campaign_phase'].name }}</label>
                  </div>
                  <div class="col-3">
                    <div class="row g-0 align-items-center">
                      <div class="col-6">
                        <input 
                          type="text" 
                          id="campaign_phase" 
                          [(ngModel)]="campaign_phase" 
                          name="campaign_phase" 
                          class="form-control" 
                          required 
                          [pattern]="fields['campaign_phase'].regex" 
                          [placeholder]="fields['campaign_phase'].placeholder"
                          (ngModelChange)="generateName('campaign_phase')"
                          #campaign_phaseField="ngModel">
                        </div>
                      <div class="col-6">                
                      </div>
                    </div>                      
                    <div *ngIf="campaign_phaseField.invalid && campaign_phaseField.touched" class="text-danger">
                      <i class="bi bi-exclamation-circle"></i> {{ fields['campaign_phase'].error }}
                    </div>
                  </div>
                  <div class="col-1" style="text-align: center;">
                  </div>
                  <div class="col-6">
                    <p class="text-description">{{ fields['campaign_phase'].description }}</p>
                  </div>
                </div>
  
                <!-- Campaign Funnel Stage -->
                <div class="row mb-3 align-items-center">
                  <div class="col-2">
                    <label for="campaign_funnel_stage" class="form-label">{{ fields['campaign_funnel_stage'].name }}</label>
                  </div>
                  <div class="col-3">
                    <select 
                      id="campaign_funnel_stage" 
                      [(ngModel)]="campaign_funnel_stage" 
                      name="campaign_funnel_stage" 
                      class="form-select" 
                      required
                      [pattern]="fields['campaign_funnel_stage'].regex"
                      (ngModelChange)="generateName()"
                      #campaign_funnel_stageField="ngModel">
                      <option value="">{{ fields['campaign_funnel_stage'].allowed_values_title }}</option>
                      <option *ngFor="let stage of fields['campaign_funnel_stage'].allowed_values" [value]="stage.key">{{ stage.value }}</option>
                    </select>
                    <div *ngIf="campaign_funnel_stageField.invalid && campaign_funnel_stageField.touched" class="text-danger">
                      <i class="bi bi-exclamation-circle"></i> {{ fields['campaign_funnel_stage'].error }}
                    </div>
                  </div>
                  <div class="col-1" style="text-align: center;">               
                  </div>
                  <div class="col-6">
                    <p class="text-description">{{ fields['campaign_funnel_stage'].description }}</p>
                  </div>
                </div>
  
                <!-- Campaign Type -->
                <div class="row mb-3 align-items-center">
                  <div class="col-2">
                    <label for="campaign_type" class="form-label">{{ fields['campaign_type'].name }}</label>
                  </div>
                  <div class="col-3">
                    <select 
                      id="campaign_type" 
                      [(ngModel)]="campaign_type" 
                      name="campaign_type" 
                      class="form-select" 
                      required
                      [pattern]="fields['campaign_type'].regex"
                      (ngModelChange)="generateName()"
                      #campaign_typeField="ngModel">
                      <option value="">{{ fields['campaign_type'].allowed_values_title }}</option>
                      <option *ngFor="let type of fields['campaign_type'].allowed_values" [value]="type.key">{{ type.value }}</option>
                    </select>
                    <div *ngIf="campaign_typeField.invalid && campaign_typeField.touched" class="text-danger">
                      <i class="bi bi-exclamation-circle"></i> {{ fields['campaign_type'].error }}
                    </div>
                  </div>
                  <div class="col-1" style="text-align: center;">               
                  </div>
                  <div class="col-6">
                    <p class="text-description">{{ fields['campaign_type'].description }}</p>
                  </div>
                </div>
  
                <!-- Campaign Budget Type -->
                <div class="row mb-3 align-items-center">
                  <div class="col-2">
                    <label for="campaign_budget_type" class="form-label">{{ fields['campaign_budget_type'].name }}</label>
                  </div>
                  <div class="col-3">
                    <select 
                      id="campaign_budget_type" 
                      [(ngModel)]="campaign_budget_type" 
                      name="campaign_budget_type" 
                      class="form-select" 
                      required
                      [pattern]="fields['campaign_budget_type'].regex"
                      (ngModelChange)="generateName()"
                      #campaign_budget_typeField="ngModel">
                      <option value="">{{ fields['campaign_budget_type'].allowed_values_title }}</option>
                      <option *ngFor="let type of fields['campaign_budget_type'].allowed_values" [value]="type.key">{{ type.value }}</option>
                    </select>
                    <div *ngIf="campaign_budget_typeField.invalid && campaign_budget_typeField.touched" class="text-danger">
                      <i class="bi bi-exclamation-circle"></i> {{ fields['campaign_budget_type'].error }}
                    </div>
                  </div>
                  <div class="col-1" style="text-align: center;">               
                  </div>
                  <div class="col-6">
                    <p class="text-description">{{ fields['campaign_budget_type'].description }}</p>
                  </div>
                </div>
  
                <!-- Campaign Conversion Event -->
                <div class="row mb-3 align-items-center">
                  <div class="col-2">
                    <label for="campaign_conversion_event" class="form-label">{{ fields['campaign_conversion_event'].name }}</label>
                  </div>
                  <div class="col-3">
                    <select 
                      id="campaign_conversion_event" 
                      [(ngModel)]="campaign_conversion_event" 
                      name="campaign_conversion_event" 
                      class="form-select" 
                      required
                      [pattern]="fields['campaign_conversion_event'].regex"
                      (ngModelChange)="generateName()"
                      #campaign_conversion_eventField="ngModel">
                      <option value="">{{ fields['campaign_conversion_event'].allowed_values_title }}</option>
                      <option *ngFor="let event of fields['campaign_conversion_event'].allowed_values" [value]="event.key">{{ event.value }}</option>
                    </select>
                    <div *ngIf="campaign_conversion_eventField.invalid && campaign_conversion_eventField.touched" class="text-danger">
                      <i class="bi bi-exclamation-circle"></i> {{ fields['campaign_conversion_event'].error }}
                    </div>
                  </div>
                  <div class="col-1" style="text-align: center;">               
                  </div>
                  <div class="col-6">
                    <p class="text-description">{{ fields['campaign_conversion_event'].description }}</p>
                  </div>
                </div>
  
                <!-- Country Code -->
                <div class="row mb-3 align-items-center">
                  <div class="col-2">
                    <label for="country_code" class="form-label">{{ fields['country_code'].name }}</label>
                  </div>
                  <div class="col-3">
                    <div class="row g-0 align-items-center">
                      <div class="col-6">
                        <select 
                          id="country_code" 
                          [(ngModel)]="country_code" 
                          name="country_code" 
                          class="form-select" 
                          required
                          [pattern]="fields['country_code'].regex"
                          (ngModelChange)="generateName()"
                          #country_codeField="ngModel">
                          <option value="">{{ fields['country_code'].allowed_values_title }}</option>
                          <option *ngFor="let code of fields['country_code'].allowed_values" [value]="code.key">{{ code.value }}</option>
                        </select>
                      </div>
                    </div>
                    <div *ngIf="country_codeField.invalid && country_codeField.touched" class="text-danger">
                      <i class="bi bi-exclamation-circle"></i> {{ fields['country_code'].error }}
                    </div>
                  </div>
                  <div class="col-1" style="text-align: center;">
                  </div>
                  <div class="col-6">
                    <p class="text-description">{{ fields['country_code'].description }}</p>
                  </div>
                </div>
            
                <!-- Adset Launch Date -->
                <div class="row mb-3 align-items-center">
                  <div class="col-2">
                    <label for="adset_launch_date" class="form-label">{{ fields['adset_launch_date'].name }}</label>
                  </div>
                  <div class="col-3">
                    <div class="row g-0 align-items-center">
                      <div class="col-6">
                        <input 
                          type="text" 
                          id="adset_launch_date" 
                          [(ngModel)]="adset_launch_date" 
                          name="adset_launch_date" 
                          class="form-control" 
                          required 
                          [pattern]="fields['adset_launch_date'].regex"
                          [placeholder]="fields['adset_launch_date'].placeholder"
                          (ngModelChange)="generateName()"
                          #adset_launch_dateField="ngModel">
                      </div>
                    </div>
                    <div *ngIf="adset_launch_dateField.invalid && adset_launch_dateField.touched" class="text-danger">
                      <i class="bi bi-exclamation-circle"></i> {{ fields['adset_launch_date'].error }}
                    </div>
                  </div>
                  <div class="col-1" style="text-align: center;">                                   
                  </div>
                  <div class="col-6">
                    <p class="text-description">{{ fields['adset_launch_date'].description }}</p>
                  </div>
                </div>
  
                <!-- Adset Bid Type -->
                <div class="row mb-3 align-items-center">
                  <div class="col-2">
                    <label for="adset_bid_type" class="form-label">{{ fields['adset_bid_type'].name }}</label>
                  </div>
                  <div class="col-3">
                    <select 
                      id="adset_bid_type" 
                      [(ngModel)]="adset_bid_type" 
                      name="adset_bid_type" 
                      class="form-select" 
                      required
                      [pattern]="fields['adset_bid_type'].regex"
                      (ngModelChange)="generateName()"
                      #adset_bid_typeField="ngModel">
                      <option value="">{{ fields['adset_bid_type'].allowed_values_title }}</option>
                      <option *ngFor="let code of fields['adset_bid_type'].allowed_values" [value]="code.key">{{ code.value }}</option>
                    </select>
                    <div *ngIf="adset_bid_typeField.invalid && adset_bid_typeField.touched" class="text-danger">
                      <i class="bi bi-exclamation-circle"></i> {{ fields['adset_bid_type'].error }}
                    </div>
                  </div>
                  <div class="col-1" style="text-align: center;">
                  </div>
                  <div class="col-6">
                    <p class="text-description">{{ fields['adset_bid_type'].description }}</p>
                  </div>
                </div>
                
                <!-- Adset Audience Type -->
                <div class="row mb-3 align-items-center">
                  <div class="col-2">
                    <label for="adset_audience_type" class="form-label">{{ fields['adset_audience_type'].name }}</label>
                  </div>
                  <div class="col-3">
                    <div class="row g-0 align-items-center">
                      <div class="col-6">
                        <input 
                          type="text" 
                          id="adset_audience_type" 
                          [(ngModel)]="adset_audience_type" 
                          name="adset_audience_type" 
                          class="form-control" 
                          required 
                          [pattern]="fields['adset_audience_type'].regex" 
                          [placeholder]="fields['adset_audience_type'].placeholder"
                          (ngModelChange)="generateName('adset_audience_type')"
                          #adset_audience_typeField="ngModel">
                      </div>
                      <div class="col-6">
                        <i (click)="resetFieldToDefault('adset_audience_type')" [ngbTooltip]="'Reset to default'" class="bi bi-x-circle" style="cursor: pointer; margin-left: 8px; margin-right: 25px;"></i>                 
                      </div>
                    </div>
                    <div *ngIf="adset_audience_typeField.invalid && adset_audience_typeField.touched" class="text-danger">
                      <i class="bi bi-exclamation-circle"></i> {{ fields['adset_audience_type'].error }}
                    </div>
                  </div>
                  <div class="col-1" style="text-align: center;">
                  </div>
                  <div class="col-6">
                    <p class="text-description">{{ fields['adset_audience_type'].description }}</p>
                  </div>
                </div>
            
                <!-- Adset Exclusion Type -->
                <div class="row mb-3 align-items-center">
                  <div class="col-2">
                    <label for="adset_exclusion_type" class="form-label">{{ fields['adset_exclusion_type'].name }}</label>
                  </div>
                  <div class="col-3">
                    <div class="row g-0 align-items-center">
                      <div class="col-6">
                        <input 
                          type="text" 
                          id="adset_exclusion_type" 
                          [(ngModel)]="adset_exclusion_type" 
                          name="adset_exclusion_type" 
                          class="form-control" 
                          required 
                          [pattern]="fields['adset_exclusion_type'].regex" 
                          [placeholder]="fields['adset_exclusion_type'].placeholder"
                          (ngModelChange)="generateName('adset_exclusion_type')"
                          #adset_exclusion_typeField="ngModel">
                        </div>
                      <div class="col-6">
                        <i (click)="resetFieldToDefault('adset_exclusion_type')" [ngbTooltip]="'Reset to default'" class="bi bi-x-circle" style="cursor: pointer; margin-left: 8px; margin-right: 25px;"></i>
                        <a [href]="copyMatrixUrl" [ngbTooltip]="'Open the Ads Library Matrix'" target="_blank" class="button" class="btn btn-sm btn-light">
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-table" viewBox="0 0 16 16">
                            <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm15 2h-4v3h4zm0 4h-4v3h4zm0 4h-4v3h3a1 1 0 0 0 1-1zm-5 3v-3H6v3zm-5 0v-3H1v2a1 1 0 0 0 1 1zm-4-4h4V8H1zm0-4h4V4H1zm5-3v3h4V4zm4 4H6v3h4z"/>
                          </svg>                    
                        </a>                  
                      </div>
                    </div>                      
                    <div *ngIf="adset_exclusion_typeField.invalid && adset_exclusion_typeField.touched" class="text-danger">
                      <i class="bi bi-exclamation-circle"></i> {{ fields['adset_exclusion_type'].error }}
                    </div>
                  </div>
                  <div class="col-1" style="text-align: center;">
                  </div>
                  <div class="col-6">
                    <p class="text-description">{{ fields['adset_exclusion_type'].description }}</p>
                  </div>
                </div>
  
                <!-- Adset Attribution Window -->
                <div class="row mb-3 align-items-center">
                  <div class="col-2">
                    <label for="adset_attribution_window" class="form-label">{{ fields['adset_attribution_window'].name }}</label>
                  </div>
                  <div class="col-3">
                    <select 
                      id="adset_attribution_window" 
                      [(ngModel)]="adset_attribution_window" 
                      name="adset_attribution_window" 
                      class="form-select" 
                      required
                      [pattern]="fields['adset_attribution_window'].regex"
                      (ngModelChange)="generateName()"
                      #adset_attribution_windowField="ngModel">
                      <option value="">{{ fields['adset_attribution_window'].allowed_values_title }}</option>
                      <option *ngFor="let code of fields['adset_attribution_window'].allowed_values" [value]="code.key">{{ code.value }}</option>
                    </select>
                    <div *ngIf="adset_attribution_windowField.invalid && adset_attribution_windowField.touched" class="text-danger">
                      <i class="bi bi-exclamation-circle"></i> {{ fields['adset_attribution_window'].error }}
                    </div>
                  </div>
                  <div class="col-1" style="text-align: center;">
                  </div>
                  <div class="col-6">
                    <p class="text-description">{{ fields['adset_attribution_window'].description }}</p>
                  </div>
                </div>
  
                <!-- Adset Product Set -->
                <div class="row mb-3 align-items-center">
                  <div class="col-2">
                    <label for="adset_product_set" class="form-label">{{ fields['adset_product_set'].name }}</label>
                  </div>
                  <div class="col-3">
                    <div class="row g-0 align-items-center">
                      <div class="col-6">
                        <input 
                          type="text" 
                          id="adset_product_set" 
                          [(ngModel)]="adset_product_set" 
                          name="adset_product_set" 
                          class="form-control" 
                          required 
                          [pattern]="fields['adset_product_set'].regex" 
                          [placeholder]="fields['adset_product_set'].placeholder"
                          (ngModelChange)="generateName('adset_product_set')"
                          #adset_product_setField="ngModel">
                        </div>
                      <div class="col-6">
                        <i (click)="resetFieldToDefault('adset_product_set')" [ngbTooltip]="'Reset to default'" class="bi bi-x-circle" style="cursor: pointer; margin-left: 8px; margin-right: 25px;"></i>
                        <a [href]="copyMatrixUrl" [ngbTooltip]="'Open the Ads Library Matrix'" target="_blank" class="button" class="btn btn-sm btn-light">
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-table" viewBox="0 0 16 16">
                            <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm15 2h-4v3h4zm0 4h-4v3h4zm0 4h-4v3h3a1 1 0 0 0 1-1zm-5 3v-3H6v3zm-5 0v-3H1v2a1 1 0 0 0 1 1zm-4-4h4V8H1zm0-4h4V4H1zm5-3v3h4V4zm4 4H6v3h4z"/>
                          </svg>                    
                        </a>                  
                      </div>
                    </div>                      
                    <div *ngIf="adset_product_setField.invalid && adset_product_setField.touched" class="text-danger">
                      <i class="bi bi-exclamation-circle"></i> {{ fields['adset_product_set'].error }}
                    </div>
                  </div>
                  <div class="col-1" style="text-align: center;">
                  </div>
                  <div class="col-6">
                    <p class="text-description">{{ fields['adset_product_set'].description }}</p>
                  </div>
                </div>
  
                <!-- Ad Set Test ID -->
                <div class="row mb-3 align-items-center">
                  <div class="col-2">
                    <label for="adset_test_id" class="form-label">{{ fields['adset_test_id'].name }}</label>
                  </div>
                  <div class="col-3">
                    <div class="row g-0 align-items-center">
                      <div class="col-6">
                        <input 
                          type="text" 
                          id="adset_test_id" 
                          [(ngModel)]="adset_test_id" 
                          name="adset_test_id" 
                          class="form-control" 
                          required 
                          [pattern]="fields['adset_test_id'].regex" 
                          [placeholder]="fields['adset_test_id'].placeholder"
                          (ngModelChange)="generateName()"
                          #adset_test_idField="ngModel">
                        </div>
                      <div class="col-6">
                        <i (click)="resetFieldToDefault('adset_test_id')" [ngbTooltip]="'Reset to default'" class="bi bi-x-circle" style="cursor: pointer; margin-left: 8px; margin-right: 25px;"></i>
                        <a [href]="copyMatrixUrl" [ngbTooltip]="'Open the Ads Library Matrix'" target="_blank" class="button" class="btn btn-sm btn-light">
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-table" viewBox="0 0 16 16">
                            <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm15 2h-4v3h4zm0 4h-4v3h4zm0 4h-4v3h3a1 1 0 0 0 1-1zm-5 3v-3H6v3zm-5 0v-3H1v2a1 1 0 0 0 1 1zm-4-4h4V8H1zm0-4h4V4H1zm5-3v3h4V4zm4 4H6v3h4z"/>
                          </svg>                    
                        </a>                  
                      </div>
                    </div>                          
                    <div *ngIf="adset_test_idField.invalid && adset_test_idField.touched" class="text-danger">
                      <i class="bi bi-exclamation-circle"></i> {{ fields['adset_test_id'].error }}
                    </div>
                  </div>
                  <div class="col-1" style="text-align: center;">
                  </div>
                  <div class="col-6">
                    <p class="text-description">{{ fields['adset_test_id'].description }}</p>
                  </div>
                </div>
  
                <!-- Ad Set Unique ID -->
                <div class="row mb-3 align-items-center">
                  <div class="col-2">
                    <label for="adset_unique_id" class="form-label">{{ fields['adset_unique_id'].name }}</label>
                  </div>
                  <div class="col-3">
                    <input 
                      type="text" 
                      id="adset_unique_id" 
                      [(ngModel)]="adset_unique_id" 
                      name="adset_unique_id" 
                      class="form-control" 
                      required 
                      [pattern]="fields['adset_unique_id'].regex" 
                      [placeholder]="fields['adset_unique_id'].placeholder"
                      (ngModelChange)="generateName()"
                      #adset_unique_idField="ngModel">
                    <div *ngIf="adset_unique_idField.invalid && adset_unique_idField.touched" class="text-danger">
                      <i class="bi bi-exclamation-circle"></i> {{ fields['adset_unique_id'].error }}
                    </div>
                  </div>
                  <div class="col-1" style="text-align: center;">
                  </div>
                  <div class="col-6">
                    <p class="text-description">{{ fields['adset_unique_id'].description }}</p>
                  </div>
                </div>
  
                <!-- Ad UGC Round -->
                <div class="row mb-3 align-items-center">
                  <div class="col-2">
                    <label for="ad_ugc_round" class="form-label">{{ fields['ad_ugc_round'].name }}</label>
                  </div>
                  <div class="col-3">
                    <div class="row g-0 align-items-center">
                      <div class="col-4">
                        <input 
                        type="text" 
                        id="ad_ugc_round" 
                        [(ngModel)]="ad_ugc_round" 
                        name="ad_ugc_round" 
                        class="form-control" 
                        required 
                        [pattern]="fields['ad_ugc_round'].regex" 
                        [placeholder]="fields['ad_ugc_round'].placeholder"
                        (ngModelChange)="generateName()"
                        #ad_ugc_roundField="ngModel">                          
                      </div>
                    </div>
                    <div *ngIf="ad_ugc_roundField.invalid && ad_ugc_roundField.touched" class="text-danger">
                      <i class="bi bi-exclamation-circle"></i> {{ fields['ad_ugc_round'].error }}
                    </div>
                  </div>
                  <div class="col-1" style="text-align: center;">
                  </div>
                  <div class="col-6">
                    <p class="text-description">{{ fields['ad_ugc_round'].description }}</p>
                  </div>
                </div>
  
                <!-- Creative Id -->
                <div class="row mb-3 align-items-center">
                  <div class="col-2">
                    <label for="creative" class="form-label">{{ fields['ad_creative'].name }}</label>
                  </div>
                  <div class="col-3">
                    <div class="row g-0 align-items-center">
                      <div class="col-6">
                        <input 
                          type="text" 
                          id="creative" 
                          [(ngModel)]="ad_creative" 
                          name="creative"                         
                          class="form-control" 
                          required 
                          [pattern]="fields['ad_creative'].regex" 
                          [placeholder]="fields['ad_creative'].placeholder"
                          (ngModelChange)="generateName('creative')"
                          #creativeField="ngModel">
                      </div>
                      <div class="col-6">
                        <i (click)="resetFieldToDefault('creative')" [ngbTooltip]="'Reset to default'" class="bi bi-x-circle" style="cursor: pointer; margin-left: 8px; margin-right: 25px;"></i>
                        <a [href]="creativeMatrixUrl" [ngbTooltip]="'Open the Creative Analysis Matrix'" target="_blank" class="button" class="btn btn-sm btn-light">
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-table" viewBox="0 0 16 16">
                            <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm15 2h-4v3h4zm0 4h-4v3h4zm0 4h-4v3h3a1 1 0 0 0 1-1zm-5 3v-3H6v3zm-5 0v-3H1v2a1 1 0 0 0 1 1zm-4-4h4V8H1zm0-4h4V4H1zm5-3v3h4V4zm4 4H6v3h4z"/>
                          </svg>                    
                        </a>                  
                        <a href="https://business.facebook.com/asset_library/ad_accounts?ad_account={{ account_id }}" [ngbTooltip]="'Open Meta Media Library'" target="_blank" class="button" class="btn btn-sm btn-light" style="margin-left: 5px;">
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-facebook" viewBox="0 0 16 16">
                            <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951"/>
                          </svg>                    
                        </a>                        
                      </div>
                    </div>
                    <div *ngIf="creativeField.invalid && creativeField.touched" class="text-danger">
                      <i class="bi bi-exclamation-circle"></i> {{ fields['ad_creative'].error }}
                    </div>
                  </div>
                  <div class="col-1" style="text-align: center; padding: 0;">
                  </div>
                  <div class="col-6">
                    <p *ngIf="!creativeMetaThumbnailLoading && !creativeMatrixLoading && !creativeCheckedInMeta && !creativeCheckedInMatrix"  class="text-description">{{ fields['ad_creative'].description }}</p>
                    <span *ngIf="creativeMetaThumbnailLoading" class="spinner-border spinner-border-sm" role="status" style="margin-right: 15px;">
                      <span class="visually-hidden">Loading...</span>
                    </span>
                    <span *ngIf="creativeCheckedInMeta && !creativeMetaThumbnailLoading" style="margin-right: 15px;">
                      <a *ngIf="creativeMetaThumbnailUrl" [href]="creativeMetaThumbnailUrl" target="_blank"><img [src]="creativeMetaThumbnailUrl" style="width: 50px; height: 50px; border: 3px solid #0082fb; border-radius: 4px;"></a>
                      <a *ngIf="!creativeMetaThumbnailUrl" href="https://business.facebook.com/asset_library/ad_accounts?ad_account={{ account_id }}" class="button btn btn-sm btn-warning" target="_blank">Not in Meta Media Library</a>
                    </span>                  
                    <span *ngIf="creativeMatrixLoading" class="spinner-border spinner-border-sm" role="status" style="margin-right: 15px;">
                      <span class="visually-hidden">Loading...</span>
                    </span>
                    <span *ngIf="creativeCheckedInMatrix && !creativeMatrixLoading">
                      <a *ngIf="creativeMatrixValue" [href]="creativeMatrixValue" class="button btn btn-sm btn-success" target="_blank">Assets in Google Drive</a>
                      <a *ngIf="!creativeMatrixValue" href="{{ creativeMatrixUrl }}" class="button btn btn-sm btn-warning" target="_blank">Not in Creative Matrix</a>
                    </span>
                  </div>
                </div>
            
                <!-- Headline Id -->
                <div class="row mb-3 align-items-center">
                  <div class="col-2">
                    <label for="headline" class="form-label">{{ fields['ad_headline'].name }}</label>
                  </div>
                  <div class="col-3">
                    <div class="row g-0 align-items-center">
                      <div class="col-6">
                        <input 
                          type="text" 
                          id="headline" 
                          [(ngModel)]="ad_headline" 
                          name="headline" 
                          class="form-control" 
                          required 
                          [pattern]="fields['ad_headline'].regex"
                          [placeholder]="fields['ad_headline'].placeholder"
                          (ngModelChange)="generateName('headline')"
                          #headlineField="ngModel">
                      </div>
                      <div class="col-6">
                        <i (click)="resetFieldToDefault('headline')" [ngbTooltip]="'Reset to default'" class="bi bi-x-circle" style="cursor: pointer; margin-left: 8px; margin-right: 25px;"></i>
                        <a [href]="copyMatrixUrl" [ngbTooltip]="'Open the Ads Library Matrix'" target="_blank" class="button" class="btn btn-sm btn-light">
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-table" viewBox="0 0 16 16">
                            <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm15 2h-4v3h4zm0 4h-4v3h4zm0 4h-4v3h3a1 1 0 0 0 1-1zm-5 3v-3H6v3zm-5 0v-3H1v2a1 1 0 0 0 1 1zm-4-4h4V8H1zm0-4h4V4H1zm5-3v3h4V4zm4 4H6v3h4z"/>
                          </svg>                    
                        </a>                  
                      </div>
                    </div>                      
                    <div *ngIf="headlineField.invalid && headlineField.touched" class="text-danger">
                      <i class="bi bi-exclamation-circle"></i> {{ fields['ad_headline'].error }}
                    </div>
                  </div>
                  <div class="col-1" style="text-align: center;">
                  </div>
                  <div class="col-6">
                    <p *ngIf="!headlineMatrixValue && !headlineMatrixLoading" class="text-description">{{ fields['ad_headline'].description }}</p>
                    <span *ngIf="headlineMatrixLoading" class="spinner-border spinner-border-sm" role="status">
                      <span class="visually-hidden">Loading...</span>
                    </span>
                    <div [innerHTML]="headlineMatrixValue" *ngIf="headlineMatrixValue && !headlineMatrixLoading" class="alert alert-success" style="margin: 0; padding: 6px 12px;"></div>
                  </div>
                </div>
            
                <!-- Primary Text Id -->
                <div class="row mb-3 align-items-center">
                  <div class="col-2">
                    <label for="primary_text" class="form-label">{{ fields['ad_primary_text'].name }}</label>
                  </div>
                  <div class="col-3">
                      <div class="row g-0 align-items-center">
                        <div class="col-6">
                          <input 
                            type="text" 
                            id="primary_text" 
                            [(ngModel)]="ad_primary_text" 
                            name="primary_text" 
                            class="form-control" 
                            required 
                            [pattern]="fields['ad_primary_text'].regex" 
                            [placeholder]="fields['ad_primary_text'].placeholder"
                            (ngModelChange)="generateName('primary_text')"
                            #primary_textField="ngModel">
                        </div>
                        <div class="col-6">
                          <i (click)="resetFieldToDefault('primary_text')" [ngbTooltip]="'Reset to default'" class="bi bi-x-circle" style="cursor: pointer; margin-left: 8px; margin-right: 25px;"></i>
                          <a [href]="copyMatrixUrl" [ngbTooltip]="'Open the Ads Library Matrix'" target="_blank" class="button" class="btn btn-sm btn-light">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-table" viewBox="0 0 16 16">
                              <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm15 2h-4v3h4zm0 4h-4v3h4zm0 4h-4v3h3a1 1 0 0 0 1-1zm-5 3v-3H6v3zm-5 0v-3H1v2a1 1 0 0 0 1 1zm-4-4h4V8H1zm0-4h4V4H1zm5-3v3h4V4zm4 4H6v3h4z"/>
                            </svg>                    
                          </a>                  
                        </div>
                      </div>                      
                       <div *ngIf="primary_textField.invalid && primary_textField.touched" class="text-danger">
                      <i class="bi bi-exclamation-circle"></i> {{ fields['ad_primary_text'].error }}
                    </div>
                  </div>
                  <div class="col-1" style="text-align: center;">
                  </div>
                  <div class="col-6">
                    <p *ngIf="!primary_textMatrixValue && !primary_textMatrixLoading" class="text-description">{{ fields['ad_primary_text'].description }}</p>
                    <span *ngIf="primary_textMatrixLoading" class="spinner-border spinner-border-sm" role="status">
                      <span class="visually-hidden">Loading...</span>
                    </span>
                    <div [innerHTML]="primary_textMatrixValue" *ngIf="primary_textMatrixValue && !primary_textMatrixLoading" class="alert alert-success" style="margin: 0; padding: 6px 12px;"></div>
                  </div>
                </div>
  
                <!-- Description Id -->
                <div class="row mb-3 align-items-center">
                  <div class="col-2">
                    <label for="description" class="form-label">{{ fields['ad_description'].name }}</label>
                  </div>
                  <div class="col-3">
                      <div class="row g-0 align-items-center">
                        <div class="col-6">
                          <input 
                            type="text" 
                            id="description" 
                            [(ngModel)]="ad_description" 
                            name="description" 
                            class="form-control" 
                            required 
                            [pattern]="fields['ad_description'].regex"
                            [placeholder]="fields['ad_description'].placeholder"
                            (ngModelChange)="generateName('description')"
                            #descriptionField="ngModel">
                        </div>
                        <div class="col-6">
                          <i (click)="resetFieldToDefault('description')" [ngbTooltip]="'Reset to default'" class="bi bi-x-circle" style="cursor: pointer; margin-left: 8px; margin-right: 25px;"></i>
                          <a [href]="copyMatrixUrl" [ngbTooltip]="'Open the Ads Library Matrix'" target="_blank" class="button" class="btn btn-sm btn-light">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-table" viewBox="0 0 16 16">
                              <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm15 2h-4v3h4zm0 4h-4v3h4zm0 4h-4v3h3a1 1 0 0 0 1-1zm-5 3v-3H6v3zm-5 0v-3H1v2a1 1 0 0 0 1 1zm-4-4h4V8H1zm0-4h4V4H1zm5-3v3h4V4zm4 4H6v3h4z"/>
                            </svg>                    
                          </a>                  
                        </div>
                      </div>                      
                     <div *ngIf="descriptionField.invalid && descriptionField.touched" class="text-danger">
                      <i class="bi bi-exclamation-circle"></i> {{ fields['ad_description'].error }}
                    </div>
                  </div>
                  <div class="col-1" style="text-align: center;">
                  </div>
                  <div class="col-6">
                    <p *ngIf="!descriptionMatrixValue && !descriptionMatrixLoading" class="text-description">{{ fields['ad_description'].description }}</p>
                    <span *ngIf="descriptionMatrixLoading" class="spinner-border spinner-border-sm" role="status">
                      <span class="visually-hidden">Loading...</span>
                    </span>
                    <div [innerHTML]="descriptionMatrixValue" *ngIf="descriptionMatrixValue && !descriptionMatrixLoading" class="alert alert-success" style="margin: 0; padding: 6px 12px;"></div>
                  </div>
                </div>
  
                <!-- Creative URL Id -->
                <div class="row mb-3 align-items-center">
                  <div class="col-2">
                    <label for="creative_url" class="form-label">{{ fields['ad_url'].name }}</label>
                  </div>
                  <div class="col-3">
                      <div class="row g-0 align-items-center">
                        <div class="col-6">
                          <input 
                            type="text" 
                            id="creative_url" 
                            [(ngModel)]="ad_url" 
                            name="creative_url" 
                            class="form-control" 
                            required 
                            [pattern]="fields['ad_url'].regex"
                            [placeholder]="fields['ad_url'].placeholder"
                            (ngModelChange)="generateName('ad_url')"
                            #creative_urlField="ngModel">
                            </div>
                        <div class="col-6">
                          <i (click)="resetFieldToDefault('creative_url')" [ngbTooltip]="'Reset to default'" class="bi bi-x-circle" style="cursor: pointer; margin-left: 8px; margin-right: 25px;"></i>
                          <a [href]="copyMatrixUrl" [ngbTooltip]="'Open the Ads Library Matrix'" target="_blank" class="button" class="btn btn-sm btn-light">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-table" viewBox="0 0 16 16">
                              <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm15 2h-4v3h4zm0 4h-4v3h4zm0 4h-4v3h3a1 1 0 0 0 1-1zm-5 3v-3H6v3zm-5 0v-3H1v2a1 1 0 0 0 1 1zm-4-4h4V8H1zm0-4h4V4H1zm5-3v3h4V4zm4 4H6v3h4z"/>
                            </svg>                    
                          </a>                  
                        </div>
                      </div>                      
                       <div *ngIf="creative_urlField.invalid && creative_urlField.touched" class="text-danger">
                      <i class="bi bi-exclamation-circle"></i> {{ fields['ad_url'].error }}
                    </div>
                  </div>
                  <div class="col-1" style="text-align: center;">
                  </div>
                  <div class="col-6">
                    <p *ngIf="!creative_urlMatrixValue && !creative_urlMatrixLoading" class="text-description">{{ fields['ad_url'].description }}</p>
                    <span *ngIf="creative_urlMatrixLoading" class="spinner-border spinner-border-sm" role="status">
                      <span class="visually-hidden">Loading...</span>
                    </span>
                    <div [innerHTML]="creative_urlMatrixValue" *ngIf="creative_urlMatrixValue && !creative_urlMatrixLoading" class="alert alert-success" style="margin: 0; padding: 6px 12px;"></div>
                  </div>
                </div>
            
                <!-- Click Destination -->
                <div class="row mb-3 align-items-center">
                  <div class="col-2">
                    <label for="click_destination" class="form-label">{{ fields['ad_click_destination'].name }}</label>
                  </div>
                  <div class="col-3">
                    <select 
                      id="click_destination" 
                      [(ngModel)]="ad_click_destination" 
                      name="click_destination" 
                      class="form-select" 
                      required
                      [pattern]="fields['ad_click_destination'].regex"
                      (ngModelChange)="generateName()"
                      #click_destinationField="ngModel">
                      <option value="">{{ fields['ad_click_destination'].allowed_values_title }}</option>
                      <option *ngFor="let destination of fields['ad_click_destination'].allowed_values" [value]="destination.key">{{ destination.value }}</option>
                    </select>
                    <div *ngIf="click_destinationField.invalid && click_destinationField.touched" class="text-danger">
                      <i class="bi bi-exclamation-circle"></i> {{ fields['ad_click_destination'].error }}
                    </div>
                  </div>
                  <div class="col-1" style="text-align: center;">               
                  </div>
                  <div class="col-6">
                    <p class="text-description">{{ fields['ad_click_destination'].description }}</p>
                  </div>
                </div>
                          
                <!-- Creative Source -->
                <div class="row mb-3 align-items-center">
                  <div class="col-2">
                    <label for="creative_source" class="form-label">{{ fields['ad_creative_source'].name }}</label>
                  </div>
                  <div class="col-3">
                    <select 
                      id="creative_source" 
                      [(ngModel)]="ad_creative_source" 
                      name="creative_source" 
                      class="form-select" 
                      required
                      [pattern]="fields['ad_creative_source'].regex"
                      (ngModelChange)="generateName()"
                      #creative_sourceField="ngModel">
                      <option value="">{{ fields['ad_creative_source'].allowed_values_title }}</option>
                      <option *ngFor="let source of fields['ad_creative_source'].allowed_values" [value]="source.key">{{ source.value }}</option>
                    </select>
                    <div *ngIf="creative_sourceField.invalid && creative_sourceField.touched" class="text-danger">
                      <i class="bi bi-exclamation-circle"></i> {{ fields['ad_creative_source'].error }}
                    </div>
                  </div>
                  <div class="col-1" style="text-align: center;">
                  </div>
                  <div class="col-6">
                    <p class="text-description">{{ fields['ad_creative_source'].description }}</p>
                  </div>
                </div>
  
                <!-- Product Category -->
                <div class="row mb-3 align-items-center">
                  <div class="col-2">
                    <label for="product_category" class="form-label">{{ fields['ad_product_category'].name }}</label>
                  </div>
                  <div class="col-3">
                    <select 
                      id="product_category" 
                      [(ngModel)]="ad_product_category" 
                      name="product_category" 
                      class="form-select" 
                      required
                      [pattern]="fields['ad_product_category'].regex"
                      (ngModelChange)="generateName()"
                      #product_categoryField="ngModel">
                      <option value="">{{ fields['ad_product_category'].allowed_values_title }}</option>
                      <option *ngFor="let category of fields['ad_product_category'].allowed_values" [value]="category.key">{{ category.value }}</option>
                    </select>
                    <div *ngIf="product_categoryField.invalid && product_categoryField.touched" class="text-danger">
                      <i class="bi bi-exclamation-circle"></i> {{ fields['ad_product_category'].error }}
                    </div>
                  </div>
                  <div class="col-1" style="text-align: center;">
                  </div>
                  <div class="col-6">
                    <p class="text-description">{{ fields['ad_product_category'].description }}</p>
                  </div>
                </div>
  
                <!-- Product Code -->
                <div class="row mb-3 align-items-center">
                  <div class="col-2">
                    <label for="product_code" class="form-label">{{ fields['ad_product_code'].name }}</label>
                  </div>
                  <div class="col-3">
                    <div class="row g-0 align-items-center">
                      <div class="col-5">
                        <select 
                          id="product_code" 
                          [(ngModel)]="ad_product_code" 
                          name="product_code" 
                          class="form-select" 
                          [pattern]="fields['ad_product_code'].regex"
                          (ngModelChange)="generateName()"
                          #product_codeField="ngModel">
                          <option value="">{{ fields['ad_product_code'].allowed_values_title }}</option>
                          <option *ngFor="let code of fields['ad_product_code'].allowed_values" [value]="code.key">{{ code.value }}</option>
                        </select>
                      </div>
                      <div class="col-2" style="text-align: center;">or</div>
                      <div class="col-5">                        
                        <input 
                            type="text" 
                            id="product_code_pds" 
                            [(ngModel)]="ad_product_code_pds" 
                            name="product_code_pds" 
                            class="form-control" 
                            [pattern]="'^PDS(\\d{3}|XXX)$'"
                            placeholder="PDSXXX"
                            (ngModelChange)="generateName()"
                            #product_code_pdsField="ngModel">                        
                      </div>                      
                    </div>
                    <div *ngIf="product_codeField.invalid && product_codeField.touched" class="text-danger">
                      <i class="bi bi-exclamation-circle"></i> {{ fields['ad_product_code'].error }}
                    </div>
                    <div *ngIf="product_code_pdsField.invalid && product_code_pdsField.touched" class="text-danger">
                      <i class="bi bi-exclamation-circle"></i> Enter a valid PDS code (PDSXXX)
                    </div>
                  </div>
                  <div class="col-1" style="text-align: center;">
                  </div>
                  <div class="col-6">
                    <p class="text-description">{{ fields['ad_product_code'].description }}</p>
                  </div>
                </div>
                          
                <!-- Post Id -->
                <div class="row mb-3 align-items-center">
                  <div class="col-2">
                    <label for="post_id" class="form-label">{{ fields['ad_post_id'].name }}</label>
                  </div>
                  <div class="col-3">
                      <div class="row g-0 align-items-center">
                        <div class="col-10">
                          <input 
                            type="text" 
                            id="post_id" 
                            [(ngModel)]="ad_post_id" 
                            name="post_id" 
                            class="form-control" 
                            required 
                            [pattern]="fields['ad_post_id'].regex"
                            [placeholder]="fields['ad_post_id'].placeholder"
                            (ngModelChange)="generateName()"
                            #post_idField="ngModel">
                        </div>
                        <div class="col-2">
                          <i (click)="resetFieldToDefault('post_id')" [ngbTooltip]="'Reset to default'" class="bi bi-x-circle" style="cursor: pointer; margin-left: 8px;"></i>
                        </div>
                      </div>                      
                     <div *ngIf="post_idField.invalid && post_idField.touched" class="text-danger">
                      <i class="bi bi-exclamation-circle"></i> {{ fields['ad_post_id'].error }}
                    </div>
                  </div>
                  <div class="col-1" style="text-align: center;">
                  </div>
                  <div class="col-6">
                    <p class="text-description">{{ fields['ad_post_id'].description }}</p>
                  </div>
                </div>
  
              </form>
            
              <div class="list-group" style="margin-top: 40px;">
                <div class="list-group-item list-group-item-action" style="background-color: #212529;">
                  <div class="row align-items-center">
                    <div class="col-6">
                      <h3 style="font-size: 1.5rem; margin-bottom: 0; color: white;">Generated Name</h3>
                    </div>
                    <div class="col-6" style="text-align: right;">
                      <button type="button" class="btn btn-sm btn-light" (click)="copyToClipboard()" [ngbTooltip]="'Copy to clipboard'" style="border-color: white; border-width: 2px;">
                        <i class="bi bi-clipboard"></i>
                      </button>
                      <button *ngIf="generatedNameValid && adsetId != ''" type="button" class="btn btn-sm btn-light" (click)="createAdOpen()" [ngbTooltip]="'Create a new ad using this name'" style="margin-left: 10px; border-color: white; border-width: 2px;">
                        <i class="bi bi-facebook"></i> Create New Ad
                      </button>
                      <button *ngIf="adId != ''" type="button" class="btn btn-sm btn-light" (click)="renameAdOpen()" [ngbTooltip]="'Rename the ad using this name'" [disabled]="!generatedNameValid || loadingAdRename" style="margin-left: 5px; border-color: white; border-width: 2px;">
                        <i class="bi bi-facebook"></i> Rename Ad
                      </button>
                      
                    </div>
                  </div>
                </div>
                <div class="list-group-item list-group-item-action">
                      <div id="generatedNameDisplay" [innerHTML]="highlightText(generatedName)" [ngClass]="generatedNameClass" class="alert text-break" role="alert" style="margin: 5px 0;"></div>
                </div>
              </div>
  
              <div *ngIf="showRenameAd" class="list-group" style="margin-top: 15px;">
                <div class="row gy-2 gx-3 align-items-center d-flex justify-content-end">
                  <div class="col-auto">
                    <div class="input-group">
                      <div class="input-group-text">Ad #</div>
                      <input type="text" [(ngModel)]="adId" class="form-control" id="newName" [placeholder]="adId">
                      <button type="button" [disabled]="loadingAdRename" class="btn btn-dark" (click)="renameAd()">Rename</button>              
                    </div>
                  </div>
                  <div *ngIf="loadingAdRename" class="col-auto">
                    <span class="spinner-grow spinner-grow-sm text-primary" role="status">
                      <span class="visually-hidden">Loading...</span>
                    </span>
                  </div>
                </div>
              </div>
  
              <div *ngIf="showCreateAd" class="list-group" style="margin-top: 40px;">
                <div class="list-group-item list-group-item-action" style="background-color: #212529;">
                  <div class="row align-items-center">
                    <div class="col-3">
                      <h3 style="font-size: 1.5rem; margin-bottom: 0; color: white;">Create New Ad</h3>
                    </div>
                    <div class="col-9" style="text-align: right;">                                        
                      <span class="text-break" (click)="openModalSelectAdset()" style="color: white; cursor: pointer; border: 1px solid white; border-radius: 4px; margin-right: 4px; font-size: 95%; padding: 3px 6px;">{{ adsetName }}</span>
                  </div>
                  </div>
                </div>
                <div class="list-group-item list-group-item-action">
                  <div *ngIf="!urlCreatedAd">
                    <div class="row" style="margin-top: 30px;">
                      <div class="col-6">
                        <div style="font-size: 1.1em; font-weight: bold;">Headline</div>
                        <div class="adspecs" [innerHTML]="headlineMatrixValue"></div>
                        <div style="font-size: 1.1em; font-weight: bold; margin-top: 20px;">Primary Text</div>
                        <div class="adspecs" [innerHTML]="primary_textMatrixValue"></div>
                        <div style="font-size: 1.1em; font-weight: bold; margin-top: 20px;">Description</div>
                        <div class="adspecs" [innerHTML]="descriptionMatrixValue" style="min-height: 119px;"></div>
                      </div>
                      <div class="col-6">
                        <div style="font-size: 1.1em; font-weight: bold;">Select a Page ID</div>
                        <div *ngIf="pageIdsLoading" style="text-align: center;">
                          <span class="spinner-border" role="status" style="margin: 10px 0;">
                            <span class="visually-hidden">Loading...</span>
                          </span>
                        </div>
                        <div *ngIf="!pageIdsLoading" style="border: 1px solid black; border-radius: 5px; max-height: 140px; overflow-y: auto;">
                          <div *ngFor="let page of pageIds" class="alert alert-light" style="margin: 5px 0;">
                            <input name="creative_pageid" type="radio" [(ngModel)]="selectedPageId" [value]="page.id" style="margin-right: 15px;" />{{ page.name }}<span class="badge rounded-pill bg-secondary" style="margin-left: 15px;">{{ page.id }}</span>                    
                          </div>
                        </div>
                        <div style="font-size: 1.1em; font-weight: bold; margin-top: 20px;">Select a Creative Image</div>
                        <div *ngIf="imagesloading" style="text-align: center;">
                          <span class="spinner-border" role="status" style="margin: 10px 0;">
                            <span class="visually-hidden">Loading...</span>
                          </span>
                        </div>
                        <div *ngIf="!imagesloading" style="border: 1px solid black; border-radius: 5px; max-height: 140px; overflow-y: auto;">
                          <div *ngFor="let image of images" class="alert alert-light" style="margin: 5px 0;">
                            <div class="row align-items-center">
                              <div class="col-1" style="text-align: center; vertical-align: middle;">
                                <input name="creative_imagehash" type="radio" [(ngModel)]="selectedImageHash" [value]="image.hash" />
                              </div>
                              <div class="col-2">
                                <img [src]="image.url" style="width: 50px; height: 50px; border: 1px solid grey; border-radius: 4px;">
                              </div>
                              <div class="col-9">
                                <div>{{ image.name }}</div>
                              </div>
                            </div>                    
                          </div>
                        </div>
                        <div style="font-size: 1.1em; font-weight: bold; margin-top: 20px;">URL</div>
                        <div class="adspecs">{{ creative_urlMatrixValue }}</div>
                        <div style="font-size: 1.1em; font-weight: bold; margin-top: 20px;">Post ID</div>
                        <div class="adspecs">{{ ad_post_id }}</div>
                        <div style="font-size: 1.1em; font-weight: bold; margin-top: 20px;">URL parameters</div>
                        <div>
                          <textarea class="form-control" id="creativeUrlTags" [(ngModel)]="creativeUrlTags" rows="4" [placeholder]="'utm_source=...'"></textarea>
                        </div>
                      </div>
                    </div>
                    <div style="margin: 15px 0; text-align: center;">
                      <button *ngIf="!loadingAdCreate" type="button" class="btn btn-dark" [disabled]="!selectedImageHash" (click)="createAd()">Create</button>
                      <span *ngIf="loadingAdCreate" class="spinner-border" role="status" style="margin-left: 10px;">
                        <span class="visually-hidden">Loading...</span>
                      </span>
                    </div>
                  </div>
                  <div *ngIf="urlCreatedAd">
                      <div style="margin: 50px 0; text-align: center;">
                        <div class="form-label" style="margin-bottom: 25px; font-size: 1.5em; font-weight: bold; color: green;"><i class="bi bi-check-circle" style="margin-right: 15px; font-size: 1.5em; vertical-align: middle;"></i>Ad created!</div>
                        <div><a [href]="urlCreatedAd" target="_blank" class="btn btn-primary btn-sm">Open in Ads Manager</a></div>
                    </div>
                  </div>                
                </div>
              </div>

          </div>

      </div>    
  </div>
</div>


<ng-template #modalSelectAdset let-modal>
	<div class="modal-header">
		<h4 class="modal-title">Select an Ad Set</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
	</div>
	<div class="modal-body">
    <div style="font-weight: bold; margin-bottom: 10px;">Campaign</div>
    <div class="list-group" style="height: 150px; overflow-y: auto;">
      <a *ngFor="let campaign of accountCampaigns" (click)="selectCampaign(campaign);" style="cursor: pointer;" class="list-group-item list-group-item-action align-middle">
        <div class="text-break">{{ campaign.name }}</div>
        <div class="text-break text-black-50" style="font-size: 90%;"><span class="badge rounded-pill" [class.bg-success]="campaign.status === 'ACTIVE'" [class.bg-secondary]="campaign.status !== 'ACTIVE'" style="margin-right: 6px;">{{ campaign.status }}</span>{{ campaign.id }} - {{ campaign.created_time | date:'longDate' }}</div>
      </a>
      <span *ngIf="accountCampaignsLoading" class="spinner-border spinner-border-sm" role="status" style="margin-right: 15px;">
        <span class="visually-hidden">Loading Campaigns...</span>
      </span>  
    </div>

    <div style="font-weight: bold; margin-top: 30px; margin-bottom: 10px;">Ad Set</div>
    <div class="list-group" class="list-group" style="height: 150px; overflow-y: auto;">
      <a *ngFor="let adset of campaignAdsets" (click)="selectAdset(adset); modal.dismiss();" style="cursor: pointer;" class="list-group-item list-group-item-action align-middle">
        <div class="text-break">{{ adset.name }}</div>
        <div class="text-break text-black-50" style="font-size: 90%;"><span class="badge rounded-pill" [class.bg-success]="adset.status === 'ACTIVE'" [class.bg-secondary]="adset.status !== 'ACTIVE'" style="margin-right: 6px;">{{ adset.status }}</span>{{ adset.id }} - {{ adset.created_time | date:'longDate' }}</div>
      </a>
      <span *ngIf="campaignAdsets.length == 0 && !campaignAdsetsLoading" class="text-break text-black-50" style="font-size: 90%; font-style: italic;">Select a campaign...</span>
      <span *ngIf="campaignAdsetsLoading" class="spinner-border spinner-border-sm" role="status" style="margin-right: 15px;">
        <span class="visually-hidden">Loading Ad Sets...</span>
      </span>
     </div>
    
	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-dark" (click)="modal.close('Close click')">Close</button>
	</div>
</ng-template>

<ng-template #modalImportAd let-modal>
	<div class="modal-header">
		<h4 class="modal-title">Import an Ad</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
	</div>
	<div class="modal-body">
    <div class="row g-3 align-items-center">
      <div class="col-auto">
        <label for="importAdId" class="col-form-label">Ad ID</label>
      </div>
      <div class="col-auto">
        <input type="text" [(ngModel)]="importAdId" class="form-control" id="importAdId" (keydown.enter)="importAd()" [placeholder]="'120214494...'">
      </div>
      <div class="col-auto">
        <button type="button" class="btn btn-sm btn-dark" (click)="importAd()">Import</button>
      </div>
    </div>
	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-dark" (click)="modal.close('Close click')">Close</button>
	</div>
</ng-template>