<div class="container">

    <div class="row" style="margin: 0 30px;">
        <div class="col">
            <nav style="--bs-breadcrumb-divider: url(&#34;data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='currentColor'/%3E%3C/svg%3E&#34;); margin-bottom: 30px;" aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a routerLink="/ad-accounts">Ad Accounts</a></li>
                    <li class="breadcrumb-item"><a routerLink="/ad-accounts/{{ accountId }}">{{ accountId | titlecase }}</a></li>
                    <li class="breadcrumb-item active" aria-current="page">Creative Analysis Assistant</li>
                </ol>
            </nav>
            
        </div>    
    </div>
</div>